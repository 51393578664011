import { Icon } from '@cb/apricot-react'
import { getDayNumberInYear, addWeeksToRange, sortDates } from '@myap/ui-library'
import Assignment from './AssignmentEvent'
import { addYearAttr, turnToWeeks } from './utils'

export const EventsByDay = (events, showSectionName) => {
  const hasEvents = events.length

  if (!hasEvents) return []

  const formattedEvents = sortDates(events).reduce((arr, e) => {
    const { courses, date, endDate, title, content, link } = e
    const multipleAssignments = courses
      ? courses.length > 1 || courses[0].sections.length > 1
      : false // more than one course or one course with more than one section

    return [
      ...arr,
      {
        date,
        endDate,
        content: courses ? (
          <Assignment {...e} showSectionName={showSectionName} />
        ) : content ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : null,
        title: courses ? (
          `Assignment${multipleAssignments ? 's' : ''} Due`
        ) : (
          <>
            <Icon name="cal-full" /> {title}
          </>
        ),
        dayCount: getDayNumberInYear(date),
        actions: link ? [link] : null,
      },
    ]
  }, [])

  return addYearAttr(formattedEvents)
}

export const EventsByWeek = (events, educationPeriod) => {
  const sorted = sortDates(events)
  const hasEvents = sorted.length

  return hasEvents
    ? turnToWeeks(
        educationPeriod.academicYrStartDate,
        addWeeksToRange(educationPeriod.academicYrEndDate, 4),
        sorted
      )
    : []
}
