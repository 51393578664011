import { useQuery } from '@apollo/client'
import { teacherCoursesQuery } from '../../../appsync/graphql/teacher'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useTeacherCoursesQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(teacherCoursesQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getTeacherData ?? []
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useTeacherCoursesQuery
