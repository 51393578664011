import { useQuery } from '@apollo/client'
import { districtInvoicesQuery } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictInvoicesQuery = ({ orgId, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
    client,
  } = useQuery(districtInvoicesQuery, {
    variables: { orgId },
    skip: !orgId || otherSkipConditions,
  })
  const data = rawData?.getDistrictInvoices ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useDistrictInvoicesQuery
