import { Icon } from '@cb/apricot-react'
import { getElementMeasurements, getViewportHeight, getWindowOffsetTop } from '@myap/ui-library'
import { getShadowRootNode } from '../../../../utilities/dom'
import styles from './timeline.module.scss'

export default ({ anchorId, anchorTitle, showAnchor }) => {
  const anchor = getShadowRootNode().querySelector(`#${anchorId}`)
  const [show, setShow] = useState(showAnchor)
  const buttonRef = useRef(null)

  // todo account for sticky navbars??
  // todo transitioning solution for focus, rather than timeout

  const updateScroll = () => {
    const { top } = getElementMeasurements(anchor)
    const { height = 0 } = getElementMeasurements(buttonRef.current)
    const viewportHeight = getViewportHeight()
    setShow(showAnchor && !(top + height < viewportHeight))
  }

  useEffect(() => {
    setShow(showAnchor)
  }, [showAnchor])

  useEffect(() => {
    window.addEventListener('scroll', updateScroll)
    return () => window.removeEventListener('scroll', updateScroll)
  })

  return show && anchorId ? (
    <button
      type="button"
      ref={buttonRef}
      className={styles['course-anchor']}
      onClick={() => {
        const { top } = getElementMeasurements(anchor)
        const offset = getWindowOffsetTop()
        window.scrollTo({ top: top + offset, behavior: 'smooth' })
        setTimeout(() => anchor.focus(), 1000) // allow animation to complete before setting focus
      }}
    >
      <span>{anchorTitle || 'My Courses'}</span>
      <Icon name="down" />
    </button>
  ) : null
}
