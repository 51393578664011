const HeaderTitle = ({ srOnly, title }) =>
  srOnly ? <span className="sr-only">{title}</span> : title

export default ({ tierHeader = null, header = [], tbody }) => (
  <div className="cb-table-responsive">
    <table className="cb-table">
      <thead>
        {tierHeader ? (
          <tr>
            {tierHeader.map(({ colspan = null, title = '', srOnly = false }, i) => (
              <th
                key={i}
                rowSpan={colspan ? null : 2}
                colSpan={colspan}
                scope={colspan ? 'colgroup' : 'col'}
              >
                <HeaderTitle srOnly={srOnly} title={title} />
              </th>
            ))}
          </tr>
        ) : null}
        <tr>
          {header.map((h, i) => (
            <th className={h.className} scope="col" key={i}>
              <HeaderTitle {...h} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{tbody}</tbody>
    </table>
  </div>
)
