import { Children, cloneElement, useEffect } from 'react'
import { Icon } from '@cb/apricot-react'
import styles from './progressslider.module.scss'

const getBookends = (activeItem = 0, items, visibleCount) => {
  const end = items.length
  const isActiveGreaterThanLen = activeItem + visibleCount >= end
  const start = isActiveGreaterThanLen ? end - visibleCount : activeItem
  return { start, end: start + visibleCount }
}

export default ({
  visibleCount,
  items,
  children,
  className = '',
  activeItem = 0,
  updateActive = false,
  ariaLabel,
  sliderId,
}) => {
  const [visibleItems, setVisibleItems] = useState(getBookends(activeItem, items, visibleCount))
  const [active, setActive] = useState(activeItem)
  const { start, end } = visibleItems
  const childrenWithProps = Children.map(children, child =>
    cloneElement(child, { items: items.slice(start, end), active, setActive })
  )

  const goForwards = () => setVisibleItems({ start: start + 1, end: end + 1 })
  const goBackwards = () => setVisibleItems({ start: start - 1, end: end - 1 })

  const setActiveForwards = () => {
    const update = active + 1
    setActive(update)
    if (update >= end) goForwards()
  }

  const setActiveBackwards = () => {
    const update = active - 1
    setActive(update)
    if (update < start) goBackwards()
  }

  useEffect(() => {
    setVisibleItems(getBookends(active, items, visibleCount))
  }, [visibleCount])

  useEffect(() => {
    setVisibleItems(getBookends(activeItem, items, visibleCount))
    setActive(activeItem)
  }, [activeItem, items])

  return (
    <div
      className={`${styles['progress-slider-container']} ${className}`}
      role="region"
      aria-label={ariaLabel}
    >
      <button
        type="button"
        disabled={updateActive ? active <= 0 : start <= 0}
        aria-label="See previous"
        className={`${styles['btn-left']}`}
        onClick={updateActive ? setActiveBackwards : goBackwards}
        aria-controls={sliderId}
      >
        <Icon name="left" />
      </button>
      <div
        className={`${styles['progress-slider-content']} cb-panel-content`}
        aria-live="polite"
        id={sliderId}
      >
        {childrenWithProps}
      </div>
      <button
        type="button"
        disabled={updateActive ? active >= items.length - 1 : end >= items.length}
        aria-label="See next"
        className={styles['btn-right']}
        onClick={updateActive ? setActiveForwards : goForwards}
        aria-controls={sliderId}
      >
        <Icon name="right" />
      </button>
    </div>
  )
}
