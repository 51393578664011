import { useId } from 'react'
import { Icon, Tooltip } from '@cb/apricot-react'
import { getShadowRootNode } from '../../../utilities/dom'

import styles from './_apricotoverrides.module.scss'

export const QuestionTooltip = ({ children }) => {
  const initialTooltipId = useId()
  const [tooltipTrigger, setTooltipTrigger] = useState(null)
  const tooltipId = `tooltip_${initialTooltipId.replaceAll(':', '')}`

  useEffect(() => {
    setTooltipTrigger(getShadowRootNode().querySelector(`#${tooltipId}`))
  }, [])

  return (
    <>
      <Icon
        id={tooltipId}
        className={styles['question-tooltip']}
        name="question"
        circular
        tabIndex={0}
      />
      {tooltipTrigger ? (
        <Tooltip
          trigger={tooltipTrigger}
          placement="top"
          className={styles['icon-tooltip']}
          shadowRoot
        >
          {children}
        </Tooltip>
      ) : null}
    </>
  )
}

export const Checkmark = () => <Icon name="check" color="green3" />

export const IconExclamation = () => (
  <Icon className={styles['icon-exclamation']} name="exclamation" circular />
)

export const Alert = ({ text, offset }) => (
  <div className={`${styles['alert']} ${offset ? styles['alert-offset'] : ''}`}>
    <IconExclamation />
    {text}
  </div>
)

export const LinkWithIcon = ({
  icon = 'east',
  link,
  newWindow,
  title,
  leftAlignIcon,
  className = '',
  ...linkProps
}) => {
  const newWindowProps = newWindow
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
        title: `Go to ${title} (Opens in a new window)`,
      }
    : {}
  const iconHtml = <Icon className={leftAlignIcon ? styles['icon-left'] : ''} name={icon} />

  return (
    <a
      href={link}
      {...newWindowProps}
      className={`${styles['link-with-icon']} ${className}`}
      {...linkProps}
    >
      {leftAlignIcon ? iconHtml : null}
      {title}
      {!leftAlignIcon ? iconHtml : null}
    </a>
  )
}

/*export const ButtonWithIcon = ({
  icon = 'east',
  onClick,
  title,
  leftAlignIcon,
  className = '',
}) => {
  const iconHtml = <Icon className={leftAlignIcon ? styles['icon-left'] : ''} name={icon} />

  return (
    <button onClick={onClick} className={`btn-link ${styles['link-with-icon']} ${className}`}>
      {leftAlignIcon ? iconHtml : null}
      {title}
      {!leftAlignIcon ? iconHtml : null}
    </button>
  )
}*/
