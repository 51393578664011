import { useQuery } from '@apollo/client'
import { studentInfoAndRegistrationQuery } from '../../../appsync/graphql/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentInfoAndRegistrationQuery = ({
  educationPeriod,
  refresh = false,
  otherSkipConditions = false,
}) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(studentInfoAndRegistrationQuery, {
    variables: { code: educationPeriod, isStudent: true, refresh },
    skip: !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getStudentInfoAndRegistration ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useStudentInfoAndRegistrationQuery
