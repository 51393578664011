import { useId } from 'react'
import { BareModal, Icon } from '@cb/apricot-react'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import { useAnalyticsStudentProfileAndRegTabEvent } from '../../../hooks/analyticsHooks'
import RegistrationForm from './RegistrationForm'
import ProfileForm from './ProfileForm'
import { adjustModalHeight } from '../../../utilities/dom'
import { PROFILE, REGISTRATION } from './constants'

const ID = 'studentProfileAndRegistrationModal'

function UpdatePersonalInformationModal() {
  // Get user details
  const {
    educationPeriod: { isRegistered: hasCurrentYearRegistration },
  } = useUserSettingsQuery()
  const modalHeaderId = useId()
  const [selectedForm, setSelectedForm] = useState(
    hasCurrentYearRegistration ? PROFILE : REGISTRATION
  )
  const [open, setOpen] = useState(true)

  useAnalyticsStudentProfileAndRegTabEvent({ tab: selectedForm })

  const isStandAlone = false
  const useAnimation = true

  const changeForm = e => setSelectedForm(e.target.value)

  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <BareModal
      modalId={ID}
      open={open}
      openAnimation={useAnimation}
      closeAnimation={useAnimation}
      clickOverlayToClose={!isStandAlone}
      escClose={!isStandAlone}
      shadowRoot
      modalNode
    >
      <div className="cb-modal-header cb-modal-has-close">
        <h2 className="cb-modal-title" id={`apricot_modal_header_${modalHeaderId}`}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="cb-margin-bottom-8">My AP Profile</div>
            {hasCurrentYearRegistration ? (
              <div
                className="cb-toggles-btn"
                role="radiogroup"
                aria-label="View AP Profile and AP Registration forms"
                style={{ width: '320px' }}
              >
                <label style={{ width: '160px', lineHeight: '24px' }}>
                  <input
                    type="radio"
                    id="profileToggle"
                    name="profileRegistrationToggle"
                    value="profile"
                    onChange={changeForm}
                    checked={selectedForm === PROFILE}
                  />
                  <span style={{ width: '160px', fontSize: '18px' }}>AP Profile</span>
                </label>
                <label style={{ width: '160px', lineHeight: '24px' }}>
                  <input
                    type="radio"
                    id="registrationToggle"
                    name="profileRegistrationToggle"
                    value="registration"
                    onChange={changeForm}
                    checked={selectedForm === REGISTRATION}
                  />
                  <span style={{ width: '160px', fontSize: '18px' }}>AP Registration</span>
                </label>
              </div>
            ) : null}
          </div>
        </h2>
        <button
          type="button"
          className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
          aria-describedby={`apricot_modal_header_${modalHeaderId}`}
          data-cb-modal-close="true"
        >
          <span />
          <Icon name="x-mark" />
          <span className="sr-only">Close Modal</span>
        </button>
      </div>
      {selectedForm === PROFILE ? (
        <ProfileForm withHeader={false} adjustHeight={adjustHeight} />
      ) : (
        <RegistrationForm withHeader={false} adjustHeight={adjustHeight} />
      )}
    </BareModal>
  )
}

UpdatePersonalInformationModal.displayName = 'Update Personal Information Modal'

export default UpdatePersonalInformationModal
