import { useApolloClient } from '@apollo/client'
import { Formik, Form } from 'formik'
import { Error, InputField } from '@myap/ui-library'
import { Modal, PrimaryButton } from '@cb/apricot-react'
import useStudentJoinCourseDetailsQuery from '../../hooks/useStudentJoinCourseDetailsQuery'
import { setJoinCourseDetails } from '../../../../appsync/actions/student'
import * as c from './constants'

import styles from '../joincourse.module.scss'

function FooterActions({
  isValid,
  values: { [c.LIVED_FIELD]: lived, [c.SPOKEN_FIELD]: spoken },
  enrollments,
  changeStep,
}) {
  const client = useApolloClient()

  // Get join course details
  const { data: joinCourseData } = useStudentJoinCourseDetailsQuery({})

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <PrimaryButton
        disabled={!isValid}
        onClick={async () => {
          setJoinCourseDetails(client, {
            ...joinCourseData,
            [c.LIVED_FIELD]: lived === 'true',
            [c.SPOKEN_FIELD]: spoken === 'true',
          })
          changeStep(!enrollments?.length ? c.INFORMATION : c.SAVE)
        }}
      >
        Continue
      </PrimaryButton>
    </div>
  )
}

function SurveyStep({ changeStep, enrollments }) {
  return (
    <Formik
      initialValues={{ [c.LIVED_FIELD]: '', [c.SPOKEN_FIELD]: '' }}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ isValid, values, error }) => (
        <Modal
          title="Language Questions"
          footer={
            <FooterActions
              isValid={isValid}
              values={values}
              enrollments={enrollments}
              changeStep={changeStep}
            />
          }
          open={true}
          openAnimation={false}
          closeAnimation={false}
          shadowRoot
          modalNode
        >
          <div className={styles.survey}>
            <p>
              Since you have indicated you are enrolling in an AP language course, please answer the
              following questions. Your responses will not affect the score of your exam.
            </p>
            <div className="cb-spacerv-16" />
            <Form>
              <fieldset>
                <legend className="cb-required">
                  By the time you may take the exam, will you have lived or studied for one month or
                  more in a country where the language of the exam you are taking is spoken?
                </legend>
                <div className="cb-spacerv-8" />
                <InputField
                  label="Yes"
                  value="true"
                  name={c.LIVED_FIELD}
                  type="radio"
                  required={true}
                  hideRequiredIndicator={true}
                />
                <div className="cb-spacerv-8" />
                <InputField
                  label="No"
                  value="false"
                  name={c.LIVED_FIELD}
                  type="radio"
                  required={true}
                  hideRequiredIndicator={true}
                />
              </fieldset>
              <fieldset>
                <legend className="cb-required">
                  Do you regularly speak or hear the language at home?
                </legend>
                <div className="cb-spacerv-8" />
                <InputField
                  label="Yes"
                  value="true"
                  name={c.SPOKEN_FIELD}
                  type="radio"
                  required={true}
                  hideRequiredIndicator={true}
                />
                <div className="cb-spacerv-8" />
                <InputField
                  label="No"
                  value="false"
                  name={c.SPOKEN_FIELD}
                  type="radio"
                  required={true}
                  hideRequiredIndicator={true}
                />
              </fieldset>
              {error ? <Error message={error} /> : null}
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  )
}

export default SurveyStep
