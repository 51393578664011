import { formatDate } from '@myap/ui-library'
import { USER_PREVIEW_STUDENT, USER_PREVIEW_TEACHER } from '@myap/metadata'
import { setRole } from '../../appsync/actions/settings'

export const processJSON = (json, start, end) =>
  JSON.parse(
    JSON.stringify(json)
      .replace(/Y1/g, formatDate(start, 'yyyy'))
      .replace(/Y2/g, formatDate(end, 'yyyy'))
  )

export const getPreviewList = ({ client, previews }) => {
  const getPreviewTitle = type => {
    switch (type) {
      case USER_PREVIEW_STUDENT:
        return 'Student View'
      case USER_PREVIEW_TEACHER:
        return 'Teacher View'
      default:
        return null
    }
  }

  return previews.map(p => ({
    title: getPreviewTitle(p),
    onClick: e => {
      e.preventDefault()
      setRole(client, p)
    },
  }))
}
