import { iam } from '@myap/ui-library'

const useCatapultAuth = () => {
  const { getEventBus, events, getTempAWSCreds, getAuthSession, getLocalTimeDifferenceInSecs } =
    iam()
  const { isLoggedIn, basicProfile = {} } = getAuthSession()
  const bus = getEventBus()
  const { AWSLogin, AWSLoginFailed, Login, Logout, InvalidLocalClock } = events
  const hasAWSCreds = Boolean(getTempAWSCreds('apfym'))
  const [awsAuth, setAWSAuth] = useState({
    loading: !hasAWSCreds,
    loaded: hasAWSCreds,
    error: false,
  })
  const [timeError, setTimeError] = useState(null)
  const setAWSAuthLogin = () => setAWSAuth({ loaded: true, error: false, loading: false })
  const setAWSAuthLoginFailed = () => setAWSAuth({ loaded: false, error: true, loading: false })
  const [isAuthed, setAuth] = useState(isLoggedIn)
  const setLoggedIn = () => setAuth(true)
  const setLoggedOut = () => setAuth(false)
  const getTimeDiff = () => {
    const diff = getLocalTimeDifferenceInSecs()
    const abs = Math.abs(diff)
    if (abs > 300)
      setTimeError(
        `It looks like your computer system clock is over 5 minutues too ${
          diff > 0 ? 'fast' : 'slow'
        }. Please update your clock and try again.`
      )
  }

  useEffect(() => {
    bus.on(AWSLogin, setAWSAuthLogin)
    bus.on(AWSLoginFailed, setAWSAuthLoginFailed)
    bus.on(Logout, setLoggedOut)
    bus.on(Login, setLoggedIn)
    bus.on(InvalidLocalClock, getTimeDiff)
    return () => {
      bus.off(AWSLogin, setAWSAuthLogin)
      bus.off(AWSLoginFailed, setAWSAuthLoginFailed)
      bus.off(Logout, setLoggedOut)
      bus.off(Login, setLoggedIn)
      bus.off(InvalidLocalClock, getTimeDiff)
    }
  }, [])

  return { isAuthed, basicProfile, timeError, ...awsAuth }
}

export default useCatapultAuth
