import { useLazyQuery } from '@apollo/client'
import { getSystemDatesQuery } from '../appsync/graphql/settings'
import { formatAppsyncErrors } from '../appsync/utils'

const useSystemDatesLazyQuery = () => {
  const [getSystemDatesLazyQuery, { loading, error: rawError = null, data: rawData = {} }] =
    useLazyQuery(getSystemDatesQuery)
  const data = rawData?.getSystemDates ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return {
    getSystemDatesLazyQuery,
    data,
    loading,
    error,
  }
}

export default useSystemDatesLazyQuery
