import { Icon, ErrorNotification } from '@cb/apricot-react'
import orderBy from 'lodash/orderBy'
import { Spinner } from '@myap/ui-library'
import Banner from '../_common/banner/Banner'
import CourseCards from '../teacher/coursecards/CourseCards'
import Resources from '../teacher/resources/Resources'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import APClassroomNavigation from '../teacher/navigation/APClassroomNavigation'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useTeacherCoursesQuery from '../teacher/hooks/useTeacherCoursesQuery'
import useTeacherRegistrationQuery from '../teacher/hooks/useTeacherRegistrationQuery'
import useTimeline from '../professional/hooks/useTimeline'
import { getTeacherAssignments } from '../_common/content/timeline/utils'
import Notifications from '../_common/content/notifications/Notifications'
import { flagCourseTypes, bodyTitle } from '../_common/content/utils'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'
import TeacherDemographics from '../professional/userinforequests/TeacherDemographics'
import TeacherRaceAndEthnicityModal from '../teacher/registration'
import { OpenModal } from '../_common/apricot/Modal'

const userRole = 'teacher'
const anchorId = 'teacherCourseAnchor'

function TeacherApp() {
  // Get user details
  const {
    loading: loadingUserDetails,
    userDetails: { selectedOrgId: orgId },
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  // Get teacher courses data
  const {
    data: teacherCoursesData,
    loading: loadingTeacherCourses,
    error: errorTeacherCourses,
  } = useTeacherCoursesQuery({ orgId, educationPeriod })

  // Get teacher registration
  const {
    data: teacherRegistrationData,
    loading: loadingTeacherRegistration,
    error: errorTeacherRegistration,
  } = useTeacherRegistrationQuery({})

  // Get Timeline events
  const { events, dates } = useTimeline({ courses: teacherCoursesData })

  const { hasPreAP, hasAP } = flagCourseTypes(teacherCoursesData)
  const title = bodyTitle({ hasAP, hasPreAP })
  const { examDates } = dates

  return (
    <>
      <Banner
        subHeader={!loadingUserDetails ? <SchoolToggle /> : null}
        navigation={
          !loadingTeacherCourses ? (
            <APClassroomNavigation courses={teacherCoursesData} hasAP={hasAP} hasPreAP={hasPreAP} />
          ) : null
        }
      />
      {hasAP ? <TimelineTeaser events={events} /> : null}
      <Notifications courses={teacherCoursesData} userRole={userRole} showAsDropdown={false} />
      <TeacherDemographics />
      <div id={anchorId} className="container cb-margin-top-48">
        <div className="row">
          <div className="col-md-8 col-sm 6 col-xs-12">
            <h2 className="cb-h4 cb-margin-bottom-24">Explore {title} Classroom Resources</h2>
          </div>
          <div className="col-md-4 col-sm 6 col-xs-12">
            {!loadingTeacherRegistration && !teacherRegistrationData?.scrub ? (
              <button
                type="button"
                className="cb-btn cb-btn-naked cb-no-padding cb-no-outline"
                onClick={() => OpenModal(TeacherRaceAndEthnicityModal, {})}
              >
                My AP Profile <Icon name="east" />
              </button>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            {loadingTeacherCourses ? (
              <Spinner spinnerId="CourseCardsSpinner" />
            ) : errorTeacherCourses ? (
              <ErrorNotification>{errorTeacherCourses}</ErrorNotification>
            ) : (
              <CourseCards
                userRole={userRole}
                courses={orderBy(teacherCoursesData, ['courseName'], ['asc'])}
                examDates={examDates}
              />
            )}
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <Resources courses={teacherCoursesData} userRole={userRole} id="teacherResourcesList" />
          </div>
        </div>
        {hasAP ? (
          <ProfessionalTimeline
            events={[...events, ...getTeacherAssignments(teacherCoursesData)]}
            anchorId={anchorId}
          />
        ) : null}
      </div>
    </>
  )
}

export default TeacherApp
