export const PROFILE = 'profile'
export const REGISTRATION = 'registration'

export const FIELD_SCHOOLNAME = 'attendingOrgName'
export const FIELD_SCHOOLID = 'attendingOrgId'
export const FIELD_GRADE = 'grade'
export const FIELD_ZIP = 'zip'
export const FIELD_STUDENTID = 'orgStudentId'
export const FIELD_LANG = 'bestLanguageCode'
export const FIELD_ETHNICITY = 'hispanicOriginCodes'
export const FIELD_RACE = 'raceCodes'
export const FIELD_PARENT1 = 'guardian1EducationLevelCode'
export const FIELD_PARENT2 = 'guardian2EducationLevelCode'
export const FIELD_ORG_COUNTRY = 'org_countryCd'
export const FIELD_ORG_CITY = 'org_city'
export const FIELD_ORG_STATE = 'org_stateCd'
export const FIELD_RECOGNIZED_TRIBE = 'enrolledRecognizedTribeInd'
export const FIELD_ENROLLED_TRIBE = 'enrolledTribeCd'
export const FIELD_DESCENDANT_TRIBE = 'descendantTribe'

export const FIELD_PREFERREDFIRSTNAME = 'preferredFirstName'
export const FIELD_GENDER = 'gender'
export const FIELD_ANOTHER_GENDER = 'anotherGender'
export const FIELD_EMAIL1 = 'emailAddress'
export const FIELD_EMAIL2 = 'email2'
export const FIELD_STREET1 = 'streetAddr1'
export const FIELD_STREET2 = 'streetAddr2'
export const FIELD_STREET3 = 'streetAddr3'
export const FIELD_CITY = 'student_city'
export const FIELD_STATE = 'stateCd'
export const FIELD_POSTALCODE = 'postalCode'
export const FIELD_PROVINCE = 'province'
export const FIELD_COUNTRY = 'countryCd'
export const FIELD_DOMESTIC_INTL = 'domesticOrIntl'

export const VALUE_FEMALE = 'FEMALE'
export const VALUE_MALE = 'MALE'
export const VALUE_ANOTHER_GENDER = 'ANOTHER'
export const VALUE_DOMESTIC = 'D'
export const VALUE_INTERNATIONAL = 'I'
export const VALUE_DOMESTIC_COUNTRY = 'US'

export const DEFAULT_STUDENT_REGISTRATION_VALUES = {
  // [FIELD_SCHOOLNAME]: '',
  // [FIELD_SCHOOLID]: '',
  [FIELD_GRADE]: '',
  [FIELD_ZIP]: '',
  [FIELD_STUDENTID]: '',
  [FIELD_LANG]: '',
  [FIELD_ETHNICITY]: [],
  [FIELD_RACE]: [],
  [FIELD_RECOGNIZED_TRIBE]: null,
  [FIELD_ENROLLED_TRIBE]: null,
  [FIELD_DESCENDANT_TRIBE]: '',
  [FIELD_PARENT1]: '',
  [FIELD_PARENT2]: '',
  [FIELD_ORG_CITY]: '',
  [FIELD_ORG_STATE]: '',
  middleInitial: null, // just to make sure middleInitial is carried through
  resetOrgInd: false, // just to make sure resetOrgInd is carried through
}

export const REQUIRED_STUDENT_REGISTRATION_VALUES = {
  [FIELD_SCHOOLID]: true,
  [FIELD_GRADE]: true,
  [FIELD_LANG]: true,
  [FIELD_ETHNICITY]: false,
  [FIELD_RACE]: false,
  [FIELD_PARENT1]: true,
}

export const DEFAULT_STUDENT_INFORMATION_VALUES = {
  [FIELD_PREFERREDFIRSTNAME]: '',
  [FIELD_GENDER]: '',
  [FIELD_ANOTHER_GENDER]: '',
  [FIELD_EMAIL1]: '',
  [FIELD_EMAIL2]: '',
  [FIELD_STREET1]: '',
  [FIELD_STREET2]: '',
  [FIELD_STREET3]: '',
  [FIELD_CITY]: '',
  [FIELD_STATE]: '',
  [FIELD_POSTALCODE]: '',
  [FIELD_PROVINCE]: '',
  [FIELD_COUNTRY]: 'US',
  [FIELD_DOMESTIC_INTL]: 'D',
}

export const REQUIRED_STUDENT_INFORMATION_VALUES = {
  [FIELD_PREFERREDFIRSTNAME]: false,
  [FIELD_GENDER]: true,
  [FIELD_ANOTHER_GENDER]: false,
  [FIELD_EMAIL1]: true,
  [FIELD_EMAIL2]: true,
  [FIELD_STREET1]: true,
  [FIELD_STREET2]: false,
  [FIELD_STREET3]: false,
  [FIELD_CITY]: true,
  [FIELD_STATE]: true,
  [FIELD_COUNTRY]: true,
  [FIELD_DOMESTIC_INTL]: true,
}
