import { useQuery } from '@apollo/client'
import { userPreferencesQuery } from '../appsync/graphql/content'
import { formatAppsyncErrors } from '../appsync/utils'

const useNotificationsQuery = ({ educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
    client,
  } = useQuery(userPreferencesQuery, {
    variables: { educationPeriod },
    skip: !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getUserPreferences ?? { deletedNotifications: [] }
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useNotificationsQuery
