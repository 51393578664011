import { useMutation } from '@apollo/client'
import { addStudentEnrollmentMutation } from '../../../appsync/graphql/student'
import { updateStudentEnrollments } from '../../../appsync/actions/student'
import { userSettingsQuery } from '../../../appsync/graphql/settings'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentAddEnrollmentMutation = ({ educationPeriod, input, onCompleted }) => {
  const [mutate, { data: rawData = {}, called, loading, error: rawError = null }] = useMutation(
    addStudentEnrollmentMutation,
    {
      variables: { code: educationPeriod, input },
      refetchQueries: [userSettingsQuery],
      onCompleted,
      update(cache, { data }) {
        // updateStudentEnrollments expects data to be a property in the object
        updateStudentEnrollments({
          cache,
          response: { data },
          key: 'addStudentEnrollment',
          educationPeriod,
        })
      },
    }
  )

  const data = rawData?.addStudentEnrollment ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, called, loading, error }
}

export default useStudentAddEnrollmentMutation
