import {
  formatDate,
  getYearInWeeks,
  isDateWithinRange,
  getDifferenceInWeeks,
  isDatePast,
} from '@myap/ui-library'

const formatAssignments = (
  arr,
  { assignments, sectionName, courseName, testCd, assignmentsLink },
  includeStartDate
) => {
  const assignmentsHaveDates = assignments.find(a => a.dueDate || (includeStartDate && a.startDate))

  if (!assignmentsHaveDates) return arr

  assignments.forEach(a => {
    const date = a.dueDate
      ? formatDate(a.dueDate)
      : a.startDate && includeStartDate
      ? formatDate(a.startDate)
      : null
    const index = arr.findIndex(a => a.date === date)
    const courseIndex = index !== -1 ? arr[index].courses.findIndex(a => a.testCd === testCd) : null

    if (date) {
      const section = { name: a.title, link: a.link, sectionName }
      const course = { testCd, sections: [section], courseName, assignmentsLink }

      if (index === -1) arr.push({ date, courses: [course] })
      else if (courseIndex === -1) arr[index].courses.push(course)
      else arr[index].courses[courseIndex].sections.push(section)
    }
  }, [])

  return arr
}

export const getStudentAssignments = sections => {
  return sections.reduce(
    (acc, s) => {
      return s.assignments
        ? {
            allDates: formatAssignments(acc.allDates, s, true),
            dueDatesOnly: formatAssignments(acc.dueDatesOnly, s),
          }
        : acc
    },
    { allDates: [], dueDatesOnly: [] }
  )
}

export const getTeacherAssignments = courses => {
  return courses.reduce((assignments, c) => {
    const { testCd, sections, courseName } = c
    sections.forEach(s => {
      const { sectionName, questionBank } = s
      const { details } = questionBank || {}
      const dets = details && details.map(d => ({ ...d, link: d.assignmentLink }))
      const section = { testCd, courseName, sectionName, assignments: dets }
      return dets ? formatAssignments(assignments, section) : assignments
    })
    return assignments
  }, [])
}

export const addYearAttr = (events = []) => {
  const eventsWithYear = [...events]
  const firstOfYearIndex = eventsWithYear.reduce(
    (index, e, i) => (e.dayCount < events[index].dayCount ? i : index),
    0
  )
  eventsWithYear[0].showYear = true
  eventsWithYear[firstOfYearIndex].showYear = true
  return events
}

export const turnToWeeks = (start, end, events) => {
  const weeks = getYearInWeeks(start, end)

  events.forEach(e => {
    const { date, endDate } = e
    const week = weeks.findIndex(w => isDateWithinRange(date, w.startDate, w.endDate))
    const eventWeekSpread = endDate ? getDifferenceInWeeks(date, endDate) : 0
    if (week !== -1) {
      for (let i = 0; i <= eventWeekSpread; i++) {
        const { events = [] } = weeks[week + i] || {}
        weeks[week + i].events = [...events, e]
      }
    }
  })

  const indexes = weeks.reduce(
    (arr, w, i) => (w.events || !isDatePast(w.endDate) ? [...arr, i] : arr),
    []
  )
  return weeks.slice(indexes[0], indexes[indexes.length - 1] + 1)
}
