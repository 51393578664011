import { iam, isEmpty } from '@myap/ui-library'
import styles from './banner.module.scss'

export default props => {
  const { getAuthSession } = iam()
  const { subHeader = null, navigation = null, name, bannerId, isStudent = false } = props
  const {
    basicProfile: { firstName },
  } = getAuthSession()
  const displayName = !isStudent ? `, ${firstName}` : !isEmpty(name) ? `, ${name}` : ''

  return (
    <div className={`cb-blue5-bg ${styles.banner}`} id={bannerId}>
      <div className="container">
        <div className="row">
          <div className="col-sm-7 col-xs-12">
            <h1 className={`cb-h4 ${styles.header}`}>Welcome{displayName}!</h1>
            {subHeader}
          </div>
          <div className="col-sm-5 col-xs-12 display-flex justify-content-sm-end justify-content-xs-center">
            {navigation}
          </div>
        </div>
      </div>
    </div>
  )
}
