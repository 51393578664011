import { CardSet } from '@myap/ui-library'
import Resources from '../../_common/content/resources/Resources'
import ContentBox from '../../_common/content/resources/ContentBox'
import ItemList from '../../_common/apricot/ItemList'

export default ({ courses = [], userRole, showTransitionContent, breakpoint }) => (
  <Resources
    userRole={userRole}
    courses={courses}
    showLoading={showTransitionContent || !courses.length}
    Component={({
      resources,
      setup,
      addAnotherCourse,
      transitionPastYear,
      transitionCurrentYear,
      faqs = {},
      resourceLinks = {},
    }) => {
      const cards = []
      if (resources) {
        cards.push({
          title: resources.title,
          content: <ItemList items={resources.links} />,
          className: 'cb-blue2-bg cb-white-color',
        })
      }
      if (resourceLinks.links) {
        cards.push({
          title: resourceLinks.title,
          content: <ItemList items={resourceLinks.links} />,
          className: 'cb-green1-bg cb-white-color',
        })
      }
      if (faqs.links) {
        cards.push({
          title: faqs.title,
          content: <ItemList items={faqs.links} />,
          className: 'cb-purple1-bg cb-white-color',
        })
      }
      const cMsg = courses.length ? addAnotherCourse : setup
      const tMsg = showTransitionContent && (transitionPastYear || transitionCurrentYear)

      return (
        <div className="cb-margin-top-24">
          {tMsg ? (
            <>
              {transitionPastYear ? (
                <ContentBox
                  hasContentBelow={cards?.length > 0 || transitionCurrentYear}
                  {...transitionPastYear}
                />
              ) : null}
              {transitionCurrentYear ? (
                <ContentBox
                  hasContentBelow={cards?.length > 0 || cMsg}
                  {...transitionCurrentYear}
                />
              ) : null}
            </>
          ) : cMsg ? (
            <ContentBox hasContentBelow={cards?.length > 0} {...cMsg} />
          ) : null}
          <CardSet cards={cards} />
        </div>
      )
    }}
  />
)
