import ClassroomLink from '../actions/ClassroomLink'
import styles from './coursecard.module.scss'

function CourseCardHeader({ course, subtitle, preview }) {
  const { testCd, courseName, isPreAP, disableAPClassroomLink } = course

  return (
    <ClassroomLink
      link={preview || disableAPClassroomLink ? '#' : `${testCd}/home`}
      className={styles.header}
      visuallyDisabled={disableAPClassroomLink}
    >
      <div className={styles['icon-wrapper']}>
        <div className={styles.icon} />
      </div>
      <h3 className="cb-h4 cb-roboto-bold">{courseName}</h3>
      {subtitle ? (
        <h4 className="cb-h5 cb-roboto" style={{ marginTop: '-20px', marginBottom: '20px' }}>
          {subtitle}
        </h4>
      ) : null}
      {!disableAPClassroomLink ? (
        <div className={styles['go-classroom']}>Go to {isPreAP ? 'Pre-AP' : 'AP'} Classroom</div>
      ) : (
        <div className={`${styles['go-classroom']} ${styles.hidden}`}>&nbsp;</div>
      )}
    </ClassroomLink>
  )
}

export default CourseCardHeader
