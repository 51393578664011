import { EventTimeline, EventCard } from '@cb/apricot-react'
import { formatDate, DATETIME_FORMATS } from '@myap/ui-library'

const TimelineContent = ({ isHorizontal, events = [] }) => {
  const currentYear = useRef(0)

  return (
    <EventTimeline layout={isHorizontal ? 'horizontal' : 'vertical'} ariaLabel="Timeline">
      {events.map(({ title, date, content }, i) => {
        const eventYear = parseInt(formatDate(date, DATETIME_FORMATS.longYear), 10)
        const repeatYear = eventYear === currentYear.current
        currentYear.current = !repeatYear ? eventYear : currentYear.current
        return (
          <EventCard
            key={i}
            eventYear={eventYear}
            dateTime={[formatDate(date, DATETIME_FORMATS.mediumMonthDayYear)]}
            title={title}
            description={content}
            repeatYear={repeatYear}
          />
        )
      })}
    </EventTimeline>
  )
}

export default TimelineContent
