import { Icon, NakedButton, Table, Column } from '@cb/apricot-react'
import { profile } from '@myap/ui-library'
import orderBy from 'lodash/orderBy'
import ToggleInactiveLink from '../../_common/actions/ToggleInactiveLink'
import linkStyles from './buttonlinks.module.scss'

function CourseSections({ sections = [], testCd, preview, isPreAP }) {
  const [showSections, setShowSections] = useState(false)
  const numSections = sections.length
  const sortedSections = orderBy(sections, section => section.sectionName.toLowerCase(), ['asc'])
  const pluralize = (word, count) => (count > 1 ? `${word}s` : word)

  if (numSections) {
    return (
      <>
        <NakedButton
          className={`cb-no-outline ${linkStyles['action-link']} ${
            isPreAP ? linkStyles['pre-ap'] : ''
          }`}
          onClick={e => setShowSections(!showSections)}
          icon={showSections ? 'minus' : 'plus'}
        >
          View Join {pluralize('Code', numSections)} for {numSections}{' '}
          {pluralize('Section', numSections)}
        </NakedButton>
        {showSections ? (
          <>
            <Table sortType="none" data={sortedSections}>
              <Column field="sectionName" title="Section Name" rowHeader />
              <Column field="enrollmentCount" title="Students" />
              <Column field="joinCode" title="Join Code" />
            </Table>
            <ToggleInactiveLink
              inactive={preview}
              href={`${profile().aproUrl}/courses?returnapp=apcentral`}
              className={linkStyles['action-link']}
            >
              Go to My Classes <Icon name="east" />
            </ToggleInactiveLink>
          </>
        ) : null}
      </>
    )
  }

  return (
    <ToggleInactiveLink
      inactive={preview}
      href={`${profile().aproUrl}/courses?create=${testCd}&returnapp=apcentral`}
      className={`${linkStyles['action-link']}${isPreAP ? ` ${linkStyles['pre-ap']}` : ''}`}
    >
      Add Section <Icon name="plus" />
    </ToggleInactiveLink>
  )
}

export default CourseSections
