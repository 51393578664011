import { profile, Breakpoint, Card } from '@myap/ui-library'

const replaceUrls = content =>
  content.replace(/https:\/\/myap.collegeboard.org/gi, profile().aproUrl)

const ContentBox = ({ title, content, button, hasContentBelow = false }) => (
  <div className="row">
    <div className={`col-xs-12${hasContentBelow ? ' cb-margin-bottom-24' : ''}`}>
      <Card
        title={title}
        content={<div dangerouslySetInnerHTML={{ __html: replaceUrls(content) }} />}
        action={
          button ? (
            <a href={replaceUrls(button.url)} className="cb-btn cb-btn-primary">
              {button.title}
            </a>
          ) : null
        }
      />
    </div>
  </div>
)

export default ContentBox
