import { useMutation } from '@apollo/client'
import { studentRegistrationMutation } from '../../../appsync/graphql/student'
import { updateStudentRegistration } from '../../../appsync/actions/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentUpdateRegistrationMutation = ({ educationPeriod, refresh, onCompleted }) => {
  const [mutate, { data: rawData = {}, called, loading, error: rawError = null }] = useMutation(
    studentRegistrationMutation,
    {
      onCompleted,
      update(client, { data: { saveStudentRegistration } }) {
        updateStudentRegistration({
          client,
          educationPeriod,
          data: { ...saveStudentRegistration },
          refresh,
        })
      },
    }
  )
  const data = rawData?.saveStudentRegistration ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, called, loading, error }
}

export default useStudentUpdateRegistrationMutation
