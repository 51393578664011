import { isIntentYes } from '@myap/metadata'
import {
  isDateTimeWithinRange,
  manipulateDays,
  manipulateMinutes,
  isDateTimePast,
  formatDate,
} from '@myap/ui-library'
import Exam from './Exam'

import styles from './enrollment.module.scss'

const EnrollmentStatus = ({ digitalApplicable, id, ...props }) => {
  const { isDigitalExam, examStartTime, examEndTime, makeupStatus, appInstalled } = props
  const examStartWithoutTime = formatDate(examStartTime)
  const isExamDay = isDateTimeWithinRange(
    new Date(),
    examStartWithoutTime,
    manipulateDays(examStartWithoutTime, 1)
  )
  const isExamOver = isDateTimePast(examEndTime)
  const checkinAvailableDate = manipulateMinutes(examStartTime, -30)
  const isCheckinAvailable = isDateTimePast(checkinAvailableDate)
  const isTakingDigitalExam = isIntentYes(props) && isDigitalExam

  return (
    <div
      className={`${styles['status-container']} ${
        digitalApplicable ? styles['status-digital'] : ''
      }`}
      id={id}
    >
      <Exam
        {...props}
        isCheckinAvailable={isCheckinAvailable}
        checkinAvailableDate={checkinAvailableDate}
        isExamDay={isExamDay}
        isExamOver={isExamOver}
      />
    </div>
  )
}

export default EnrollmentStatus
