import { useQuery } from '@apollo/client'
import { userSettingsQuery } from '../appsync/graphql/settings'
import { formatAppsyncErrors } from '../appsync/utils'

const useUserSettingsQuery = () => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
    client,
  } = useQuery(userSettingsQuery)
  const data = rawData?.getUserSettings ?? {}
  const { educationPeriods = [], inactiveFeatures = {}, systemDates = {}, userDetails = {} } = data
  const error = rawError ? formatAppsyncErrors(rawError) : null
  const educationPeriod =
    (Array.isArray(educationPeriods)
      ? educationPeriods.length === 1
        ? educationPeriods[0]
        : educationPeriods.find(edpd => edpd.chronology === 'current')
      : {}) ?? {}

  return {
    data,
    educationPeriods,
    educationPeriod,
    inactiveFeatures,
    systemDates,
    userDetails,
    loading,
    error,
  }
}

export default useUserSettingsQuery
