import { isIntentYes } from '@myap/metadata'
import { parseDateTime, formatDateTime, formatDate, DATETIME_FORMATS } from '@myap/ui-library'
import InactiveButton from '../../_common/actions/InactiveButton'
import OpenNewWindow from '../../_common/actions/OpenNewWindow'
import { OpenModal } from '../../_common/apricot/Modal'
import Flag from '../../_common/featureflag/Flag'
import { QuestionTooltip } from '../../_common/apricot/Icons'
import RegisterExam from '../registerforcourse'

import styles from './enrollment.module.scss'

const getUnzonedDateTime = dateTime => {
  if (!dateTime) {
    return dateTime
  }

  const dateTimeLen = dateTime.length
  // If dateTime includes a time zone
  if (dateTimeLen === 22) {
    // Need to remove the time zone so parseDateTime() doesn't return a zoned time
    return dateTime.substring(0, dateTimeLen - 6)
  }
  return dateTime
}

function ChangeExamIntentButton({ type, registrationDeadline, preview, ...props }) {
  const triggerRef = useRef(null)
  const className = registrationDeadline
    ? 'cb-btn cb-btn-sm cb-btn-primary'
    : `${styles['change-status']} cb-btn cb-btn-naked cb-btn-no-outline`
  const btnTitle = registrationDeadline ? `Register for ${type}` : 'Change Status'

  return preview ? (
    <InactiveButton content={btnTitle} />
  ) : (
    <>
      <button
        ref={triggerRef}
        onClick={() =>
          OpenModal(RegisterExam, { elem: 'SelectIntent', ...props }, triggerRef.current)
        }
        type="button"
        className={className}
      >
        {btnTitle}
      </button>
      {registrationDeadline ? (
        <p className="cb-roboto-bold">
          Deadline: {formatDateTime(registrationDeadline, 'MMM d, yyyy')}
        </p>
      ) : null}
    </>
  )
}

function ExamIntentYes({ type, orgName, city, state, country }) {
  return (
    <div className={styles['registration-status']}>
      <div>Registered for {type}</div>
      <div>
        given by{' '}
        <OpenNewWindow
          url={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            `${orgName}, ${city}, ${state || country}`
          )}`}
        >
          {orgName}{' '}
        </OpenNewWindow>
      </div>
    </div>
  )
}

function ExamIntentNo({ type }) {
  return <div className={styles['registration-status']}>Not registered for {type}</div>
}

function Exam(props) {
  const {
    examStartTime,
    studentOrTeacherCanChangeExamIntent,
    orgName,
    address,
    isDigitalExam,
    accommodations,
    isExamDay,
    isExamOver,
    requiresUnlocking,
    adminWindowDisplayName,
    checkinAvailableDate,
  } = props
  const type = examStartTime ? 'Exam' : 'Assessment'
  const FORMAT = 'MMM d, h:mm a'
  // We always want exam start times to display as the same in all time zones, so an 8:00 AM exam
  // on the east coast is also an 8:00 AM exam on the west coast.
  const unzonedExamStartTime = getUnzonedDateTime(examStartTime)
  const isExamIntentYes = isIntentYes(props)

  return (
    <div className={styles['exam-status']}>
      <h5 className={`cb-h6 ${styles['exam-day']}`}>
        <span className="cb-icon cb-cal-full" /> Exam Day
      </h5>
      {requiresUnlocking ? (
        adminWindowDisplayName
      ) : examStartTime ? (
        <>{formatDate(parseDateTime(unzonedExamStartTime), FORMAT)}</>
      ) : (
        <>See submission deadline</>
      )}
      <div className={styles['exam-status-content']}>
        {isExamIntentYes ? (
          <ExamIntentYes
            type={type}
            orgName={orgName}
            examStartTime={unzonedExamStartTime}
            {...(address || {})}
          />
        ) : (
          <ExamIntentNo type={type} />
        )}
        {studentOrTeacherCanChangeExamIntent ? (
          <ChangeExamIntentButton type={type} {...props} />
        ) : null}
      </div>
      <Flag feature="MYAP_STUDENT_DIGITAL_ACCOMMODATIONS">
        {isDigitalExam && !isExamOver ? (
          <>
            {isExamDay && isExamIntentYes ? (
              <div>
                <em>
                  <strong>
                    Check in at {formatDateTime(checkinAvailableDate, DATETIME_FORMATS.time)}
                  </strong>
                </em>
              </div>
            ) : null}
            <div className={styles['exam-status-content']}>
              <h6>
                Accommodations{' '}
                <QuestionTooltip>
                  <p>
                    These are the accommodation categories you&#39;ll use for this digital AP exam.
                    If you have questions about these accommodations, contact your AP coordinator.
                  </p>
                </QuestionTooltip>
              </h6>
              <em>
                {accommodations && accommodations.length
                  ? accommodations.join(', ')
                  : 'No accommodations'}
              </em>
            </div>
          </>
        ) : null}
      </Flag>
    </div>
  )
}

export default Exam
