import { gql } from '@apollo/client'

export const userSettingsQuery = gql`
  query getUserSettings {
    getUserSettings {
      userDetails {
        acceptedGeneralTAC
        acceptedFundingTAC
        personId
        selectedRole @client
        initialOrgId @client
        selectedOrgId
        activeRoleCd
        roles {
          isPreAPOnly
          showOrderData
          orgId
          orgName
          role
          notAbleToOrder
          needToCompletePartForm
          aiCode
          parentOrgId
          parentOrgName
          districtOrg
        }
      }
      educationPeriods {
        code
        descr
        academicYrStartDate
        academicYrEndDate
        chronology
        hasEnrollments
        isRegistered
        isTransitionPeriod @client
      }
      systemDates {
        examDates
        deadlines
        examDecisions
      }
      inactiveFeatures
    }
  }
`

export const getSystemDatesQuery = gql`
  query getSystemDates($orgId: Int, $educationPeriod: Int!) {
    getSystemDates(orgId: $orgId, educationPeriod: $educationPeriod) {
      examDates
      deadlines
      examDecisions
    }
  }
`
