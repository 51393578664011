import { HorizontalCarousel } from '@cb/apricot-react'
import { closestToDateIndex, Breakpoint } from '@myap/ui-library'
import TimelineHeader from '../../_common/content/timeline/TimelineHeader'
import TimelineContent from '../../_common/content/timeline/TimelineContent'
import {
  HORIZONTAL_VIEW,
  VERTICAL_VIEW,
  VISIBLE_COUNT,
  TITLE,
  TIMELINE_BUTTONS,
  TIMELINE_ID,
} from '../../_common/content/timeline/constants'
import { EventsByDay } from '../../_common/content/timeline/Events'

import styles from '../../_common/content/timeline/timeline.module.scss'

const ProfessionalTimeline = Breakpoint(props => {
  const { breakpoint = {}, events, anchorId, anchorTitle } = props
  const verticalOnlyView = events.length < VISIBLE_COUNT
  const showToggleButtons = !breakpoint.mobile && !verticalOnlyView
  const [view, setView] = useState(verticalOnlyView ? VERTICAL_VIEW : HORIZONTAL_VIEW)
  const isHorizontal = view === HORIZONTAL_VIEW && !breakpoint.mobile
  const startIndex = closestToDateIndex(
    new Date(),
    events.map(e => e.date)
  )

  return (
    <div className="row">
      <div className="col-xs-12 cb-margin-top-48">
        <div id={TIMELINE_ID}>
          <TimelineHeader
            title={TITLE}
            alwaysShowTitle={true}
            buttons={showToggleButtons ? TIMELINE_BUTTONS : null}
            setView={setView}
            view={view}
            isHorizontal={isHorizontal}
            anchorId={verticalOnlyView ? null : anchorId}
            anchorTitle={anchorTitle}
          />
          {isHorizontal ? (
            <HorizontalCarousel
              scrollDistance={320}
              ariaLabel="Timeline"
              variant="light"
              bgClass="cb-white-bg"
              arrowClass="cb-black1-color"
            >
              <TimelineContent isHorizontal={isHorizontal} events={events} />
            </HorizontalCarousel>
          ) : (
            <TimelineContent isHorizontal={isHorizontal} events={events} />
          )}
        </div>
      </div>
    </div>
  )
})

export default ({ events }) =>
  events.length ? <ProfessionalTimeline events={EventsByDay(events, true)} /> : null
