import { YellowButton } from '@cb/apricot-react'
import { Card } from '@myap/ui-library'
import { OpenModal } from '../../_common/apricot/Modal'
import InactiveButton from '../../_common/actions/InactiveButton'
import EnrollmentFlow from './EnrollmentFlow'
import styles from './joincourse.module.scss'

const JoinCourse = ({
  modalProps = {
    elem: 'JoinCourse',
    className: 'cb-width-xs-100-only',
  },
  title,
  content,
  preview,
}) => {
  const triggerRef = useRef(null)
  const btnTitle = 'Join a Course or Exam'

  return (
    <Card
      id="previewScreen3"
      title={title}
      content={
        <div
          className={styles['join-course-card-title']}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      }
      action={
        preview ? (
          <InactiveButton content={btnTitle} />
        ) : (
          <YellowButton
            ref={triggerRef}
            onClick={() => OpenModal(EnrollmentFlow, modalProps, triggerRef.current)}
          >
            {btnTitle}
          </YellowButton>
        )
      }
      className="cb-blue5-bg cb-white-color"
      cta={true}
    />
  )
}

JoinCourse.displayName = 'JoinCourse'

export default JoinCourse
