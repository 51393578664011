import * as c from '@myap/metadata'

export default base => ({
  [c.US_HISTORY]: `${base}/courses/ap-united-states-history`,
  [c.ART_HISTORY]: `${base}/courses/ap-art-history`,
  [c.ART_DRAWING]: `${base}/courses/ap-drawing`,
  [c.ART_2D]: `${base}/courses/ap-2-d-art-and-design`,
  [c.ART_3D]: `${base}/courses/ap-3-d-art-and-design`,
  [c.BIOLOGY]: `${base}/courses/ap-biology`,
  [c.CHEMISTRY]: `${base}/courses/ap-chemistry`,
  [c.COMPUTER_SCIENCE_A]: `${base}/courses/ap-computer-science-a`,
  [c.MACROECONOMICS]: `${base}/courses/ap-macroeconomics`,
  [c.MICROECONOMICS]: `${base}/courses/ap-microeconomics`,
  [c.ENGLISH_LANGAUGE]: `${base}/courses/ap-english-language-and-composition`,
  [c.ENGLISH_LITERATURE]: `${base}/courses/ap-english-literature-and-composition`,
  [c.ENVIRONMENTAL_SCIENCE]: `${base}/courses/ap-environmental-science`,
  [c.EUROPEAN_HISTORY]: `${base}/courses/ap-european-history`,
  [c.FRENCH_LANGUAGE]: `${base}/courses/ap-french-language-and-culture`,
  [c.HUMAN_GEOGRAPHY]: `${base}/courses/ap-human-geography`,
  [c.GERMAN_LANGUAGE]: `${base}/courses/ap-german-language-and-culture`,
  [c.US_GOVERNMENT]: `${base}/courses/ap-united-states-government-and-politics`,
  [c.COMPARATIVE_GOVERNMENT]: `${base}/courses/ap-comparative-government-and-politics`,
  [c.LATIN]: `${base}/courses/ap-latin`,
  [c.CALCULUS_AB]: `${base}/courses/ap-calculus-ab`,
  [c.CALCULUS_BC]: `${base}/courses/ap-calculus-bc`,
  [c.MUSIC_THEORY]: `${base}/courses/ap-music-theory`,
  [c.PHYSICS_MECHANICS]: `${base}/courses/ap-physics-c-mechanics`,
  [c.PSYCHOLOGY]: `${base}/courses/ap-psychology`,
  [c.SPANISH_LANGUAGE]: `${base}/courses/ap-spanish-language-and-culture`,
  [c.SPANISH_LITERATURE]: `${base}/courses/ap-spanish-literature-and-culture`,
  [c.STATISTICS]: `${base}/courses/ap-statistics`,
  [c.WORLD_HISTORY]: `${base}/courses/ap-world-history`,
  [c.PHYSICS_ELECTRICITY]: `${base}/courses/ap-physics-c-electricity-and-magnetism`,
  [c.ITALIAN_LANGUAGE]: `${base}/courses/ap-italian-language-and-culture`,
  [c.CHINESE_LANGUAGE]: `${base}/courses/ap-chinese-language-and-culture`,
  [c.JAPANESE_LANGUAGE]: `${base}/courses/ap-japanese-language-and-culture`,
  [c.PHYSICS_1]: `${base}/courses/ap-physics-1`,
  [c.PHYSICS_2]: `${base}/courses/ap-physics-2`,
  [c.SEMINAR]: `${base}/courses/ap-seminar`,
  [c.RESEARCH]: `${base}/courses/ap-research`,
  [c.COMPUTER_SCIENCE_PRINCIPLES]: `${base}/courses/ap-computer-science-principles`,
  [c.AFRICAN_AMERICAN_STUDIES]: `${base}/courses/ap-african-american-studies`,
  [c.PRE_CALCULUS]: `${base}/courses/ap-precalculus`,
})
