import { formatDate, DATETIME_FORMATS, profile, sortDates } from '@myap/ui-library'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import links from '../../_common/coursecard/links'
import ToggleInactiveLink from '../../_common/actions/ToggleInactiveLink'

import styles from './buttonlinks.module.scss'

function EventLinks({ course, preview, examDates = [] }) {
  // Get user details
  const {
    educationPeriod: { isTransitionPeriod },
  } = useUserSettingsQuery()

  const {
    testCd,
    isStudioArt,
    isDigitalPortfolio,
    isPreAP,
    digitalApplicable,
    earliestExamDate,
    noEndOfCourseExam,
    disableCourseExamLink,
  } = course
  const { apcentralUrl, aproUrl } = profile()
  const urls = links(apcentralUrl)
  const sortedDates = sortDates(examDates.map(e => ({ date: e.examDate })))
  const { date = earliestExamDate } = sortedDates[0] || {}

  if (!isPreAP)
    return (
      <div className={styles['event-links']}>
        {noEndOfCourseExam ? (
          <div>
            <strong>See Submission Deadline</strong>
          </div>
        ) : date && !isTransitionPeriod ? (
          <div>
            <i className="cb-icon cb-cal-full" aria-hidden={true} /> <strong>Exam Day</strong>{' '}
            {formatDate(date, DATETIME_FORMATS.longMonthDayYear)}
          </div>
        ) : null}
        {digitalApplicable ? (
          <ToggleInactiveLink
            inactive={preview}
            href={`${aproUrl}/digital-exam-readiness?testCd=${testCd}`}
            className={`cb-hover ${styles['event-link']}`}
          >
            Digital Exam <span className={styles['no-wrap']}></span>
          </ToggleInactiveLink>
        ) : null}
        {!disableCourseExamLink ? (
          <ToggleInactiveLink
            inactive={preview}
            href={urls[testCd]}
            className={styles['event-link']}
          >
            Go to Course & Exam{' '}
            <span className={styles['no-wrap']}>
              pages <span className="cb-icon cb-east" />
            </span>
          </ToggleInactiveLink>
        ) : null}
        {isDigitalPortfolio || isStudioArt ? (
          <ToggleInactiveLink
            inactive={preview}
            href="https://digitalportfolio.collegeboard.org/"
            className={`cb-hover ${styles['event-link']}`}
          >
            Go to Digital{' '}
            <span className={styles['no-wrap']}>
              Portfolio <span className="cb-icon cb-east" />
            </span>
          </ToggleInactiveLink>
        ) : null}
      </div>
    )

  return null
}

export default EventLinks
