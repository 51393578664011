import orderBy from 'lodash/orderBy'
import { Icon } from '@cb/apricot-react'
import styles from './timeline.module.scss'

export default ({ update, options: unsortedOptions }) => {
  const options = orderBy(unsortedOptions, ['label'], ['asc'])
  const dropdownId = 'timelineCourseFilter'
  const [filters, setFilters] = useState(options.map(o => o.value))
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    update(filters)
  }, [filters])

  function checkClickedOutsideDropdown({ target }) {
    const elem = containerRef.current
    const btn = buttonRef.current
    if (target !== btn && !btn.contains(target)) setOpen(elem && elem.contains(target))
  }

  useEffect(() => {
    document.addEventListener('click', checkClickedOutsideDropdown)
    return () => document.removeEventListener('click', checkClickedOutsideDropdown)
  }, [])

  return options.length ? (
    <div className={styles['course-filter']}>
      <button
        type="button"
        ref={buttonRef}
        onClick={() => setOpen(!isOpen)}
        id={dropdownId}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        Filter by Course
        <Icon name={isOpen ? 'up' : 'down'} />
      </button>
      {isOpen ? (
        <ul aria-labelledby={dropdownId} aria-hidden={!isOpen} ref={containerRef}>
          {options.map(o => {
            const isSelected = filters.includes(o.value)
            return (
              <li key={o.value}>
                <button
                  type="button"
                  aria-pressed={isSelected}
                  onClick={() =>
                    setFilters(
                      isSelected ? filters.filter(s => s !== o.value) : [...filters, o.value]
                    )
                  }
                >
                  {o.label}
                  {isSelected ? <Icon name="check" /> : null}
                </button>
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  ) : null
}
