export const HORIZONTAL_VIEW = 'H'
export const VERTICAL_VIEW = 'V'
export const VISIBLE_COUNT = 3
export const TITLE = 'Timeline'
export const TIMELINE_ID = 'ProfessionalTimeline'
export const HORIZONTAL_BUTTON_ID = 'HorizontalTimelineButton'
export const VERTICAL_BUTTON_ID = 'VerticalTimelineButton'

export const TIMELINE_BUTTONS = [
  {
    value: HORIZONTAL_VIEW,
    label: 'Upcoming Dates',
    icon: 'cb-nav-links',
    id: HORIZONTAL_BUTTON_ID,
  },
  { value: VERTICAL_VIEW, label: 'All Dates', icon: 'cb-menu', id: VERTICAL_BUTTON_ID },
]

export const EVENT_STANDARD_DECISION = 'standardDecisionDeadline'
export const EVENT_SECOND_DECISION = 'secondSemesterDecisionDeadline'
export const EVENT_EXAM_DATE = 'examDate'
