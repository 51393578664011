import { Glyph, CloseButton } from '@cb/apricot-react'
import { Spinner } from '@myap/ui-library'
import useUserSettingsQuery from '../../../../hooks/useUserSettingsQuery'
import useNotificationsQuery from '../../../../hooks/useNotificationsQuery'
import useDeleteNotificationMutation from '../../../../hooks/useDeleteNotificationMutation'
import { filterByCourseCategory, filterByTestCd } from '../utils'
import { getCMSNotifications } from '../../../../rest/cms'

import styles from './notifications.module.scss'

const Notification = ({ content, id, icon, educationPeriod }) => {
  const processedIcon = icon?.startsWith('cb-') ? icon.slice(3) : icon

  const {
    mutate: deleteNotification,
    loading,
    error,
  } = useDeleteNotificationMutation({ id, educationPeriod })

  return (
    <div
      className={`cb-notification cb-notification-dismissible cb-white-bg ${styles.notification}`}
      role="region"
    >
      <div className="cb-notification-container">
        <div className="cb-notification-header">
          <Glyph name={processedIcon ?? 'megaphone'} circular />
          <span dangerouslySetInnerHTML={{ __html: content }} />
          <CloseButton
            className={styles['btn-dismiss']}
            onClick={() => deleteNotification({ variables: { id, educationPeriod } })}
            label="Close notification"
          />
        </div>
      </div>
    </div>
  )
}

function Notifications({ courses, userRole, showAsDropdown = true }) {
  const [notifications, setNotifications] = useState([])
  const [open, setOpen] = useState(!showAsDropdown)

  // Get user details
  const {
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  // Get deleted notifications
  const {
    data: deletedNotificationsData,
    loading: loadingDeletedNotifications,
    error: deletedNotificationsError,
  } = useNotificationsQuery({ educationPeriod })

  const notificationsWithoutDeletes = notifications.filter(
    n => !deletedNotificationsData?.deletedNotifications?.find(d => d === n.id)
  )
  const count = notificationsWithoutDeletes.length

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getCMSNotifications(userRole)
      const filtered = filterByTestCd(courses, filterByCourseCategory(courses, data))
      setNotifications(filtered)
    }

    fetchNotifications()
  }, [])

  return !loadingDeletedNotifications && count > 0 ? (
    <>
      {showAsDropdown ? (
        <button
          type="button"
          className={styles['notifications-button']}
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          aria-controls={open ? id : null}
        >
          <Icon className={styles['notifications-icon-megaphone']} name="megaphone" />
          <span>
            {count} New Notification{count > 1 ? 's' : ''}
          </span>
          <Icon name={open ? 'minus' : 'plus'} />
        </button>
      ) : null}

      {open ? (
        <div
          role="region"
          aria-label="Notifications"
          className={`cb-gray5-bg ${showAsDropdown ? styles['notifications-dropdown'] : ''} ${
            styles.notifications
          }`}
        >
          <div className="container">
            {notificationsWithoutDeletes.map(msg => (
              <Notification key={msg.id} {...msg} educationPeriod={educationPeriod} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  ) : null
}

export default Notifications
