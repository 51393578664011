import ClassroomLink from '../../actions/ClassroomLink'

import styles from './timeline.module.scss'

export default ({ courses, date, showSectionName }) => (
  <div className={styles['event-assignment']}>
    {courses.map(c => (
      <div className={styles['event-course']} key={`${c.testCd}_${date}`}>
        <div>{c.courseName}</div>
        <ul>
          {c.sections.map((s, i) => (
            <li key={`${i}_${date}`}>
              <ClassroomLink
                link={!c.disableAPClassroomLink ? s.link : '#'}
                visuallyDisabled={c?.disableAPClassroomLink}
              >
                {showSectionName ? <strong>{s.sectionName}:</strong> : null} {s.name}
              </ClassroomLink>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
)
