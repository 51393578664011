/**
 * Link that opens new tab or window on link click while ignoring
 * other event effects.
 */
function OpenNewWindow({ url, children }) {
  return (
    <a
      href={url}
      onClick={e => {
        e.preventDefault()
        window.open(url)
      }}
    >
      {children}
    </a>
  )
}

export default OpenNewWindow
