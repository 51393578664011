import { BareModal } from '@cb/apricot-react'
import { adjustModalHeight } from '../../../utilities/dom'
import TeacherRegistrationForm from './TeacherRegistrationForm'

const ID = 'teacherRegistrationModal'

const TeacherRaceAndEthnicityModal = ({ isDismissable = true }) => {
  const [open, setOpen] = useState(true)

  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <BareModal
      modalId={ID}
      open={open}
      escClose={isDismissable}
      clickOverlayToClose={isDismissable}
      shadowRoot
      modalNode
    >
      <TeacherRegistrationForm
        setOpen={setOpen}
        adjustHeight={adjustHeight}
        isDismissable={isDismissable}
      />
    </BareModal>
  )
}

TeacherRaceAndEthnicityModal.displayName = 'TeacherRaceAndEthnicityModal'

export default TeacherRaceAndEthnicityModal
