import { parse } from '@myap/ui-library'

export default {
  userDetails: {
    fields: {
      initialOrgId: (value, operations) => {
        return value || operations.readField('selectedOrgId')
      },
      selectedOrgId: (value, operations) => {
        return value
      },
      selectedRole: (value, operations) => {
        const activeRoleCd = operations.readField('activeRoleCd')
        const orgId = operations.readField('selectedOrgId')
        const roles = operations.readField('roles') || []
        const { role } =
          activeRoleCd && orgId ? roles.find(r => r.orgId === orgId) ?? roles[0] : roles[0]
        const selectedRole = value || role

        return selectedRole
      },
    },
  },
  educationPeriod: {
    fields: {
      // The first month of the academic year is a transitional period
      isTransitionPeriod: (value, { readField }) => {
        const startDate = readField('academicYrStartDate')
        const today = new Date()
        return (
          today.getMonth() === parse(startDate).getMonth() &&
          today.getFullYear() === parse(startDate).getFullYear()
        )
      },
    },
  },
  systemDates: {
    fields: {
      examDates: value =>
        value && typeof value === 'string' ? JSON.parse(value) : value ? value : {},
      examDecisions: value =>
        value && typeof value === 'string' ? JSON.parse(value) : value ? value : {},
      deadlines: value =>
        value && typeof value === 'string' ? JSON.parse(value) : value ? value : {},
    },
  },
  studentEnrollments: {
    fields: {
      scoreSendSaved: value => value || false,
    },
  },
}
