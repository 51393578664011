import { useQuery } from '@apollo/client'
import { coordinatorQuery } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useCoordinatorCoursesQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(coordinatorQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getCoordinatorData ?? []
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useCoordinatorCoursesQuery
