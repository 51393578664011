import ToggleInactiveLink from '../actions/ToggleInactiveLink'

import styles from '../../../sass/content.module.scss'

// document will change icon from arrow to download icon
// items: [{ icon, link: { url, title }, link_type: page/document }]
// assumes all links are external and will open in a new window

export default ({ title, content, items = [], preview }) => (
  <>
    {title ? <h2 className="cb-h5">{title}</h2> : null}
    {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : null}
    <ul className={styles['callout-links']}>
      {items.map((item, i) => {
        const { link, link_type, icon } = item
        const { title, url } = link[0]
        const isDownload = link_type?.toLowerCase() === 'file'

        return (
          <li key={i} className={isDownload ? styles['list-type-document'] : ''}>
            {/*icon ? <span className={`cb-icon ${icon}`} /> : null*/}
            <ToggleInactiveLink
              href={url}
              inactive={preview}
              target={isDownload ? '_blank' : null}
              className="cb-target-blank"
              title={isDownload ? `Download ${title}` : 'Related Site'}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </ToggleInactiveLink>
          </li>
        )
      })}
    </ul>
  </>
)
