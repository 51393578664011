import { useId } from 'react'
import { Formik, Form } from 'formik'
import isBoolean from 'lodash/isBoolean'
import orderBy from 'lodash/orderBy'
import { PrimaryButton, Icon, Toasts, Toast } from '@cb/apricot-react'
import { PROVINCE_OPTIONS, STATES, COUNTRIES } from '@myap/metadata'
import {
  formatDate,
  DATETIME_FORMATS,
  isEmpty,
  Error,
  TextField,
  InputField,
  SelectField,
  Spinner,
} from '@myap/ui-library'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentInfoAndRegistrationQuery from '../hooks/useStudentInfoAndRegistrationQuery'
import useStudentUpdateInformationMutation from '../hooks/useStudentUpdateInformationMutation'
import OpenNewWindow from '../../_common/actions/OpenNewWindow'
import {
  DEFAULT_STUDENT_INFORMATION_VALUES,
  REQUIRED_STUDENT_INFORMATION_VALUES,
  FIELD_PREFERREDFIRSTNAME,
  FIELD_GENDER,
  FIELD_ANOTHER_GENDER,
  FIELD_EMAIL1,
  FIELD_EMAIL2,
  FIELD_STREET1,
  FIELD_STREET2,
  FIELD_STREET3,
  FIELD_CITY,
  FIELD_STATE,
  FIELD_POSTALCODE,
  FIELD_PROVINCE,
  FIELD_COUNTRY,
  FIELD_DOMESTIC_INTL,
  FIELD_SCHOOLNAME,
  FIELD_SCHOOLID,
  FIELD_ORG_CITY,
  FIELD_ORG_STATE,
  FIELD_ORG_COUNTRY,
  VALUE_DOMESTIC,
  VALUE_INTERNATIONAL,
  VALUE_FEMALE,
  VALUE_MALE,
  VALUE_ANOTHER_GENDER,
  VALUE_DOMESTIC_COUNTRY,
} from './constants'
import {
  validateAddressLine,
  validateCity,
  validateConfirmEmail,
  validateEmail,
  validateGenderComment,
  validatePostalCode,
  validatePreferredFirstName,
} from './validations'
import { getShadowRootNode } from '../../../utilities/dom'
import styles from './profileandregform.module.scss'

function ProfileForm({
  changeStep,
  nextStep,
  withHeader = true,
  adjustHeight,
  isReviewMissingInfo = false,
}) {
  const modalHeaderId = useId()
  const isStandAlone = typeof changeStep !== 'function'

  // Get user details
  const {
    educationPeriod: { code: educationPeriod, hasEnrollments },
  } = useUserSettingsQuery()

  const [initialValues, setInitialValues] = useState(DEFAULT_STUDENT_INFORMATION_VALUES)
  const [showToast, setShowToast] = useState(false)
  const [updateError, setUpdateError] = useState(null)
  // const [scrollingContent, setScrollingContent] = useState(false)
  let toastTimerId = null
  const refresh = !hasEnrollments && !isStandAlone

  // Send refresh parameter to getStudentInfoAndRegistration when this is student's first
  // enrollment and this is in the enrollment flow
  const variables = { code: educationPeriod, isStudent: true, refresh }

  // Get student info and registration
  const { loading, data } = useStudentInfoAndRegistrationQuery({
    educationPeriod,
    refresh,
  })

  // Mutate student information
  const { mutate: update, loading: saving } = useStudentUpdateInformationMutation({
    educationPeriod,
    refresh,
  })

  useEffect(() => {
    if (!loading && !isEmpty(data) && adjustHeight) {
      adjustHeight()
    }
  }, [loading, data, adjustHeight])

  useEffect(() => {
    let isCurrent = true
    if (!loading && !isEmpty(data) && isCurrent) {
      const keys = data ? Object.keys(data) : []
      const initialVals = keys.reduce((obj, key) => {
        if (key !== '__typename') {
          if (key === 'address') {
            const addressData = data.address
            // is addressData an object?
            if (typeof addressData === 'object' && addressData !== null) {
              // get all the keys in address
              const addressKeys = Object.keys(addressData)
              return addressKeys.reduce((obj, addrKey) => {
                if (addrKey !== '__typename' && addrKey !== 'city') {
                  return { ...obj, [addrKey]: addressData[addrKey] || '' }
                }
                // We want to rename the city field so it doesn't clash with ORG CITY
                if (addrKey === 'city') {
                  return { ...obj, [FIELD_CITY]: addressData.city || '' }
                }
                return obj
              }, obj)
            } else {
              // address was not an object, drop in default values
              return {
                ...obj,
                [FIELD_STREET1]: '',
                [FIELD_STREET2]: '',
                [FIELD_STREET3]: '',
                [FIELD_CITY]: '',
                [FIELD_STATE]: '',
                [FIELD_POSTALCODE]: '',
                [FIELD_PROVINCE]: '',
                [FIELD_COUNTRY]: '',
              }
            }
          }
          // attendingOrg can be null when student selects they are not attending a school
          // during account creation
          if (key === 'attendingOrg') {
            const attendingOrgData = data.attendingOrg
            if (attendingOrgData !== null) {
              // get all the keys in attendingOrg
              const attendingOrgKeys = Object.keys(attendingOrgData)
              return attendingOrgKeys.reduce((obj, orgKey) => {
                if (orgKey === 'orgName') {
                  return { ...obj, attendingOrgName: attendingOrgData.orgName || '' }
                }
                if (orgKey === 'orgId') {
                  return { ...obj, attendingOrgId: attendingOrgData.orgId || '' }
                }
                // We want to rename the city, stateCd, countryCd fields so they don't clash with fields of same name
                if (orgKey === 'city') {
                  return { ...obj, [FIELD_ORG_CITY]: attendingOrgData.city || '' }
                }
                if (orgKey === 'stateCd') {
                  return { ...obj, [FIELD_ORG_STATE]: attendingOrgData.stateCd || '' }
                }
                if (orgKey === 'countryCd') {
                  return { ...obj, [FIELD_ORG_COUNTRY]: attendingOrgData.countryCd || '' }
                }
                return obj
              }, obj)
            }
            // generate empty data when attendingOrg is null
            return {
              ...obj,
              [FIELD_SCHOOLNAME]: '',
              [FIELD_SCHOOLID]: null,
              [FIELD_ORG_CITY]: '',
              [FIELD_ORG_STATE]: '',
              [FIELD_ORG_COUNTRY]: '',
            }
          }
          // if any remaining value is specifically null, convert to empty string
          return {
            ...obj,
            [key]:
              isBoolean(data[key]) || data[key] !== null || Array.isArray(data[key])
                ? data[key]
                : '',
          }
        }
        return obj
      }, {})
      setInitialValues({
        ...DEFAULT_STUDENT_INFORMATION_VALUES,
        ...initialVals,
        ...(initialVals[FIELD_COUNTRY] !== VALUE_DOMESTIC_COUNTRY
          ? { [FIELD_DOMESTIC_INTL]: VALUE_INTERNATIONAL }
          : { [FIELD_DOMESTIC_INTL]: VALUE_DOMESTIC }),
        ...(initialVals[FIELD_GENDER] !== VALUE_FEMALE &&
        initialVals[FIELD_GENDER] !== VALUE_MALE &&
        initialVals[FIELD_GENDER] !== VALUE_ANOTHER_GENDER
          ? {
              [FIELD_ANOTHER_GENDER]: initialVals[FIELD_GENDER],
              [FIELD_GENDER]: VALUE_ANOTHER_GENDER,
            }
          : { [FIELD_ANOTHER_GENDER]: '' }),
      })
    }
    return () => (isCurrent = false)
  }, [data, loading])

  // console.log('Profile initialValues:', initialValues)
  const states = orderBy(
    STATES.filter(state => state.displayInd === 'Y').map(({ code, descr }) => ({
      value: code,
      label: descr,
    })),
    ['label'],
    ['asc']
  )
  const countries = orderBy(
    COUNTRIES.map(({ iso, countryName }) => ({
      value: iso.toUpperCase(),
      label: countryName,
    })).filter(({ value }) => value !== VALUE_DOMESTIC_COUNTRY),
    ['label'],
    ['asc']
  )

  const staticFieldStyles = {
    label: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontWeight: 400,
      color: '#1e1e1e',
      marginLeft: 0,
    },
    value: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#1e1e1e',
      marginLeft: 0,
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={initialValues}
      validateOnMount={false}
      onSubmit={async (values, { resetForm }) => {
        if (saving) {
          return
        }

        setUpdateError(null)

        try {
          // console.log('Student Registration values:', values)
          const {
            [FIELD_EMAIL2]: email2, // remove
            [FIELD_CITY]: city,
            [FIELD_COUNTRY]: countryCd,
            [FIELD_POSTALCODE]: postalCode,
            [FIELD_PROVINCE]: province,
            [FIELD_STATE]: stateCd,
            [FIELD_STREET1]: streetAddr1,
            [FIELD_STREET2]: streetAddr2,
            [FIELD_STREET3]: streetAddr3,
            [FIELD_ANOTHER_GENDER]: anotherGender,
            [FIELD_PREFERREDFIRSTNAME]: preferredFirstName,
            [FIELD_EMAIL1]: emailAddress,
            [FIELD_DOMESTIC_INTL]: domesticIntl, // remove
            attendingOrgId: orgId,
            attendingOrgName: orgName,
            [FIELD_ORG_CITY]: orgCity,
            [FIELD_ORG_STATE]: orgState,
            [FIELD_ORG_COUNTRY]: orgCountry,
            missingRegInfoInd, // remove
            resetOrgInd, // remove
            __typename, // remove
            ...other
          } = values // filter out UI only fields
          // reprocess all values with an empty string as null so service doesn't complain
          const processedOther = Object.keys(other).reduce((acc, key) => {
            if (other[key] === '') {
              return { ...acc, [key]: null }
            }
            return { ...acc, [key]: other[key] }
          }, {})
          const updateObj = {
            ...processedOther,
            preferredFirstName: preferredFirstName !== '' ? preferredFirstName : null,
            emailAddress,
            ...(values[FIELD_GENDER] === VALUE_ANOTHER_GENDER
              ? { gender: !isEmpty(anotherGender) ? anotherGender : values[FIELD_GENDER] }
              : { gender: values[FIELD_GENDER] }),
            address: {
              streetAddr1,
              streetAddr2: streetAddr2 !== '' ? streetAddr2 : null,
              streetAddr3: streetAddr3 !== '' ? streetAddr3 : null,
              city,
              stateCd: stateCd !== '' ? stateCd : null,
              postalCode,
              province: province !== '' ? province : null,
              countryCd,
            },
            attendingOrg: {
              orgName,
              orgId,
              city: orgCity,
              stateCd: orgState,
              countryCd: orgCountry,
            },
          }

          await update({
            variables: {
              ...variables,
              input: updateObj,
              isProfile: true,
              isStudent: true,
            },
          })
          // resetForm(values)
          if (!isStandAlone) {
            changeStep(nextStep)
          } else {
            setShowToast(true)
            toastTimerId = setTimeout(() => setShowToast(false), 5000)
          }
        } catch (err) {
          setUpdateError(err?.message)
          // console.error('Error saving student information', err)
        }
      }}
    >
      {({
        isValid,
        dirty,
        isSubmitting,
        submitForm,
        validateForm,
        values,
        errors,
        setFieldValue,
      }) => {
        const disabled = loading || isSubmitting
        const isDomesticAddress = values[FIELD_DOMESTIC_INTL] === VALUE_DOMESTIC
        const confirmationEmailRequired = values[FIELD_EMAIL1] !== initialValues[FIELD_EMAIL1]

        useEffect(() => {
          if (!isEmpty(updateError)) {
            const errorEl = getShadowRootNode().querySelector('#formError')
            errorEl.scrollIntoView(true)
            errorEl.focus()
          }
        }, [updateError])

        if (loading) {
          return (
            <div id="modalContent" className="cb-modal-content">
              <Spinner spinnerId="profileFormSpinner" size="64" />
            </div>
          )
        }

        return (
          <>
            {withHeader ? (
              <div className="cb-modal-header cb-modal-has-close">
                <h2 className="cb-modal-title" id={`apricot_modal_header_${modalHeaderId}`}>
                  {isStandAlone ? 'AP Profile' : 'AP Registration - Step 1'}
                </h2>
                <button
                  type="button"
                  className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                  aria-describedby={`apricot_modal_header_${modalHeaderId}`}
                  data-cb-modal-close="true"
                >
                  <span />
                  <Icon name="x-mark" />
                  <span className="sr-only">Close Modal</span>
                </button>
              </div>
            ) : null}
            <div id="modalContent" className="cb-modal-content">
              <Toasts bottom>
                <Toast
                  timeout={5000}
                  type="success"
                  message="AP Profile saved"
                  controlled={true}
                  show={showToast}
                  onDismiss={() => {
                    clearTimeout(toastTimerId)
                    setShowToast(false)
                  }}
                />
              </Toasts>
              {isStandAlone || isReviewMissingInfo ? (
                <p>
                  Please confirm the information below for your AP Profile. <strong>NOTE:</strong>{' '}
                  If changes are needed to your legal name or date of birth, contact AP Services for
                  Students by using the{' '}
                  <OpenNewWindow url="https://collegeboard.tfaforms.net/193">
                    contact form
                  </OpenNewWindow>{' '}
                  or calling 888-225-5427. If you make changes to any other information in this
                  registration section, you&#39;ll also need to go to your{' '}
                  <OpenNewWindow url="https://my.collegeboard.org/profile/information">
                    College Board account settings
                  </OpenNewWindow>{' '}
                  to make the same change &mdash; changes made to your AP Profile do not
                  automatically carry over to your College Board account.
                </p>
              ) : (
                <p>
                  Please confirm the information below for your AP Profile. <strong>NOTE:</strong>{' '}
                  If changes are needed to your legal name or date of birth, contact AP Services for
                  Students by using the{' '}
                  <OpenNewWindow url="https://collegeboard.tfaforms.net/193">
                    contact form
                  </OpenNewWindow>{' '}
                  or calling 888-225-5427 <strong>after completing your registration</strong>. If
                  you make changes to any other information in this registration section, you&#39;ll
                  also need to go to your{' '}
                  <OpenNewWindow url="https://my.collegeboard.org/profile/information">
                    College Board account settings
                  </OpenNewWindow>{' '}
                  to make the same change &mdash; changes made to your AP Profile do not
                  automatically carry over to your College Board account.
                </p>
              )}
              <div className="cb-spacerv-24" />
              <Form className={styles.registration}>
                {!isEmpty(updateError) ? (
                  <div id="formError" tabIndex="-1">
                    <Error title="Error" className="cb-margin-bottom-16">
                      {updateError
                        ? updateError
                        : 'There was an error saving your information. Please try again later.'}
                    </Error>
                  </div>
                ) : null}
                <fieldset style={{ marginBottom: 0 }}>
                  <div style={staticFieldStyles.label}>Legal Name</div>
                  <div style={staticFieldStyles.value}>{`${values.firstName} ${
                    !isEmpty(values.middleInitial) ? `${values.middleInitial} ` : ''
                  }${values.lastName}`}</div>
                  <div className="cb-spacerv-16" />
                  <TextField
                    label="Preferred First Name"
                    name={FIELD_PREFERREDFIRSTNAME}
                    required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_PREFERREDFIRSTNAME]}
                    autocomplete="off"
                    placeholder="Enter your preferred first name (optional)"
                    validate={validatePreferredFirstName}
                    maxLength="15"
                    disabled={disabled}
                  />
                  <div className="cb-spacerv-16" />
                  <div style={staticFieldStyles.label}>Date of Birth</div>
                  <div style={staticFieldStyles.value}>
                    {formatDate(values.dob, DATETIME_FORMATS.longMonthDayYear)}
                  </div>

                  <fieldset>
                    <legend className="cb-required">Gender</legend>
                    <InputField
                      type="radio"
                      name={FIELD_GENDER}
                      value={VALUE_FEMALE}
                      label="Female"
                      disabled={disabled}
                    />
                    <div className="cb-spacerv-16" />
                    <InputField
                      type="radio"
                      name={FIELD_GENDER}
                      value={VALUE_MALE}
                      label="Male"
                      disabled={disabled}
                    />
                    <div className="cb-spacerv-16" />
                    <InputField
                      type="radio"
                      name={FIELD_GENDER}
                      value={VALUE_ANOTHER_GENDER}
                      label="Another (not listed)"
                      disabled={disabled}
                    />
                    {values[FIELD_GENDER] === VALUE_ANOTHER_GENDER ? (
                      <>
                        <div className="cb-spacerv-16" />
                        <TextField
                          label="Please specify (optional)"
                          name={FIELD_ANOTHER_GENDER}
                          required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_ANOTHER_GENDER]}
                          autocomplete="off"
                          placeholder="Please specify (optional)"
                          maxLength="35"
                          disabled={disabled}
                          validate={validateGenderComment}
                        />
                      </>
                    ) : null}
                  </fieldset>

                  <TextField
                    label="Email address"
                    name={FIELD_EMAIL1}
                    required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_EMAIL1]}
                    autocomplete="off"
                    placeholder="Enter email address"
                    maxLength="50"
                    disabled={disabled}
                    validate={validateEmail}
                  />
                  <div className="cb-spacerv-16" />
                  {confirmationEmailRequired ? (
                    <TextField
                      className="cb-margin-top-16"
                      label="Confirm email address"
                      name={FIELD_EMAIL2}
                      required={confirmationEmailRequired}
                      autocomplete="off"
                      placeholder="Confirm email address"
                      maxLength="50"
                      disabled={disabled || !confirmationEmailRequired}
                      validate={
                        confirmationEmailRequired
                          ? validateConfirmEmail(values[FIELD_EMAIL1])
                          : undefined
                      }
                    />
                  ) : null}

                  <fieldset>
                    <legend className="cb-required">Address</legend>
                    <InputField
                      type="radio"
                      name={FIELD_DOMESTIC_INTL}
                      value={VALUE_DOMESTIC}
                      label="U.S., U.S. Territories, and Puerto Rico"
                      disabled={disabled}
                      onChange={async e => {
                        await setFieldValue(FIELD_DOMESTIC_INTL, e.target.value)
                        await setFieldValue(FIELD_STREET3, '')
                        await setFieldValue(FIELD_CITY, '')
                        await setFieldValue(FIELD_POSTALCODE, '')
                        await setFieldValue(FIELD_COUNTRY, VALUE_DOMESTIC_COUNTRY)
                        await setFieldValue(FIELD_PROVINCE, '')
                        validateForm()
                      }}
                    />
                    <div className="cb-spacerv-16" />
                    <InputField
                      type="radio"
                      name={FIELD_DOMESTIC_INTL}
                      value={VALUE_INTERNATIONAL}
                      label="Outside the U.S."
                      disabled={disabled}
                      onChange={async e => {
                        await setFieldValue(FIELD_DOMESTIC_INTL, e.target.value)
                        await setFieldValue(FIELD_CITY, '')
                        await setFieldValue(FIELD_POSTALCODE, '')
                        await setFieldValue(FIELD_STATE, '')
                        await setFieldValue(FIELD_COUNTRY, '')
                        validateForm()
                      }}
                    />
                    <div className="cb-spacerv-16" />
                    {!isDomesticAddress ? (
                      <SelectField
                        className="cb-margin-bottom-16"
                        name={FIELD_COUNTRY}
                        label="Country"
                        required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_COUNTRY]}
                        options={countries}
                        disabled={disabled}
                        onChange={value => {
                          setFieldValue(FIELD_COUNTRY, value)
                          setFieldValue(FIELD_PROVINCE, '')
                        }}
                      />
                    ) : null}
                    <TextField
                      label="Address line 1"
                      name={FIELD_STREET1}
                      required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_STREET1]}
                      autocomplete="off"
                      placeholder="Enter street address"
                      maxLength="50"
                      disabled={disabled}
                      validate={validateAddressLine}
                    />
                    <div className="cb-spacerv-16" />
                    <TextField
                      label="Address line 2"
                      name={FIELD_STREET2}
                      required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_STREET2]}
                      autocomplete="off"
                      placeholder="Enter street address"
                      maxLength="50"
                      disabled={disabled}
                      validate={validateAddressLine}
                    />
                    {!isDomesticAddress ? (
                      <TextField
                        className="cb-margin-top-16"
                        label="Address line 3"
                        name={FIELD_STREET3}
                        required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_STREET3]}
                        autocomplete="off"
                        placeholder="Enter street address"
                        maxLength="50"
                        disabled={disabled}
                        validate={validateAddressLine}
                      />
                    ) : null}
                    <div className="cb-spacerv-16" />
                    <TextField
                      label="City"
                      name={FIELD_CITY}
                      required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_CITY]}
                      autocomplete="off"
                      placeholder="Enter city"
                      maxLength="50"
                      disabled={disabled}
                      validate={validateCity}
                    />
                    <div className="cb-spacerv-16" />
                    {isDomesticAddress ? (
                      <>
                        <SelectField
                          name={FIELD_STATE}
                          label="State"
                          required={REQUIRED_STUDENT_INFORMATION_VALUES[FIELD_STATE]}
                          options={states}
                          disabled={disabled}
                          onChange={value => {
                            setFieldValue(FIELD_STATE, value)
                          }}
                        />
                        <div className="cb-spacerv-16" />
                      </>
                    ) : null}
                    <TextField
                      label={`${isDomesticAddress ? 'Zip' : 'Postal'} code`}
                      name={FIELD_POSTALCODE}
                      required={values[FIELD_COUNTRY] === 'CA' || isDomesticAddress}
                      autocomplete="off"
                      placeholder={`Enter ${isDomesticAddress ? 'zip' : 'postal'} code`}
                      maxLength={isDomesticAddress ? '5' : '10'}
                      disabled={disabled}
                      validate={validatePostalCode(isDomesticAddress)}
                    />
                    <div className="cb-spacerv-16" />
                    {!isDomesticAddress ? (
                      values[FIELD_COUNTRY] === 'CA' || values[FIELD_COUNTRY] === 'GB' ? (
                        <>
                          <SelectField
                            name={FIELD_PROVINCE}
                            label="Province"
                            required={true}
                            options={PROVINCE_OPTIONS[values[FIELD_COUNTRY]]}
                            disabled={disabled}
                            onChange={value => {
                              setFieldValue(FIELD_PROVINCE, value)
                            }}
                          />
                          <div className="cb-spacerv-16" />
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Region/Province"
                            name={FIELD_PROVINCE}
                            autocomplete="off"
                            placeholder="Enter region/province"
                            maxLength="32"
                            disabled={disabled}
                          />
                          <div className="cb-spacerv-16" />
                        </>
                      )
                    ) : null}
                  </fieldset>
                </fieldset>
              </Form>
            </div>
            <div className="cb-modal-footer">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginRight: 'auto' }}>
                  <p className="cb-margin-bottom-16">
                    Before clicking <strong>{isStandAlone ? 'Save' : 'Next'}</strong>, confirm that
                    all information entered on this form is accurate.
                  </p>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <PrimaryButton
                    disabled={!isValid || isSubmitting || (!dirty && isStandAlone)}
                    loading={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    {isStandAlone ? 'Save' : 'Next'}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default ProfileForm
