import mem from 'mem'

export const flagCourseTypes = courses => {
  const hasPreAP = courses.some(c => c.isPreAP)
  const hasAP = courses.some(c => c.isAP)
  const hasCK = courses.some(c => c.isCK)
  return { hasPreAP, hasAP, hasCK }
}

export const bodyTitle = ({ hasAP, hasPreAP }) => {
  let title = ''
  const titles = []
  if (hasAP) {
    titles.push('AP')
  }
  if (hasPreAP) {
    titles.push('Pre-AP')
  }
  title = titles.join(' & ')
  return title
}

export const getCourseFilters = mem(data => ({
  showPreAP: Boolean(data.find(e => e.isPreAP)),
  showAP: Boolean(data.find(e => e.isAP)),
  showCK: Boolean(data.find(e => e.isCK)),
  showCapstone: Boolean(data.find(e => e.isCapstone)),
  showDigitalPortfolio: Boolean(data.find(e => e.isDigitalPortfolio)),
  showStudioArt: Boolean(data.find(e => e.isStudioArt)),
}))

export const checkByCourseCategory = (courses, content) => {
  const { showCapstone, showAP, showPreAP, showDigitalPortfolio, showStudioArt } =
    getCourseFilters(courses)

  return (
    (!content.isDigitalPortfolio || showDigitalPortfolio) &&
    (!content.isCapstone || showCapstone) &&
    (!content.isStudioArt || showStudioArt) &&
    (!content.isPreAp || showPreAP) &&
    (!content.isAp || showAP) &&
    (!content.isCK || showCK)
  )
}

export const checkByTestCd = (courses, content) => {
  const testCds = courses.map(c => c.testCd)
  return !content.course || testCds.find(cd => cd === parseInt(content.course, 10))
}

export const filterByCourseCategory = (courses, content) =>
  content.filter(c => checkByCourseCategory(courses, c))

export const filterByTestCd = (courses, content) => content.filter(c => checkByTestCd(courses, c))
