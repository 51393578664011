export const FIELD_ETHNICITY = 'hispanicOriginCodes'
export const FIELD_RACE = 'raceCodes'
export const FIELD_RECOGNIZED_TRIBE = 'enrolledRecognizedTribeInd'
export const FIELD_ENROLLED_TRIBE = 'enrolledTribeCd'
export const FIELD_DESCENDANT_TRIBE = 'descendantTribe'

export const DEFAULT_TEACHER_VALUES = {
  [FIELD_ETHNICITY]: [],
  [FIELD_RACE]: [],
  [FIELD_RECOGNIZED_TRIBE]: null,
  [FIELD_ENROLLED_TRIBE]: null,
  [FIELD_DESCENDANT_TRIBE]: '',
}

export const REQUIRED_TEACHER_VALUES = {
  [FIELD_ETHNICITY]: true,
  [FIELD_RACE]: true,
}
