import { gql } from '@apollo/client'

const sectionFragment = gql`
  fragment sectionFragment on teacherSection {
    sectionId
  }
`

export const teacherCoursesQuery = gql`
  query getTeacherData($orgId: Int!, $educationPeriod: Int!) {
    getTeacherData(orgId: $orgId, educationPeriod: $educationPeriod) {
      courseName
      testCd
      isAP
      isPreAP
      isCK
      isCapstone
      isDigitalPortfolio
      isStudioArt
      digitalApplicable
      noEndOfCourseExam
      earliestExamDate
      disableAPClassroomLink
      disableSecondaryAPClassroomLinks
      disableCourseExamLink
      sections {
        sectionName
        joinCode
        examCount
        enrollmentCount
        ...sectionFragment
      }
    }
  }
  ${sectionFragment}
`

// export const teacherSubscription = gql`
//   subscription getTeacherData($teacherId: String!, $educationPeriod: Int!) {
//     getTeacherData(teacherId: $teacherId, educationPeriod: $educationPeriod) {
//       lastUpdated
//       educationPeriod
//       teacherId
//       data {
//         ...sectionFragment
//       }
//     }
//   }
//   ${sectionFragment}
// `

export const teacherRegistrationQuery = gql`
  query getTeacherRegistration($isProfessional: Boolean!) {
    getTeacherRegistration(isProfessional: $isProfessional) {
      raceCodes
      hispanicOriginCodes
      enrolledRecognizedTribeInd
      enrolledTribeCd
      descendantTribe
      lastUpdatedEpc
      userId
      canCollectEthnicities
      scrub
    }
  }
`

export const teacherRegistrationMutation = gql`
  mutation saveTeacherRegistration(
    $input: saveTeacherRegistrationInput
    $isProfessional: Boolean!
  ) {
    saveTeacherRegistration(input: $input, isProfessional: $isProfessional)
  }
`
