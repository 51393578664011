import { useQuery } from '@apollo/client'
import { coordinatorStatusQuery } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useCoordinatorStatusQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(coordinatorStatusQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getCoordinatorStatus ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useCoordinatorStatusQuery
