import { profile, formatDate } from '@myap/ui-library'
import axios from 'axios'
import mem from 'mem'

const getRequest = url =>
  axios.request({
    method: 'get',
    url,
    withCredentials: false,
    transformRequest: (data, headers) => {
      headers.common = {}
      return data
    },
  })

export const getCMSNotifications = mem(async role => {
  const { apcentralUrl } = profile()
  try {
    const { data } = await getRequest(`${apcentralUrl}/api/v1/myap/notification?userType=${role}`)
    return data
  } catch (err) {
    return []
  }
})

export const getCMSTimelineEvents = mem(async (role, period) => {
  const edPeriod = period || parseInt(formatDate(new Date(), 'yy')) + 1
  const { apcentralUrl } = profile()
  try {
    const { data } = await getRequest(
      `${apcentralUrl}/api/v1/myap/timeline?userType=${role}&eduPeriod=20${edPeriod - 2}-${
        edPeriod - 1
      }`
    )
    return data
  } catch (err) {
    return []
  }
})

export const getCMSResources = mem(async role => {
  const { apcentralUrl } = profile()
  try {
    const { data } = await getRequest(`${apcentralUrl}/api/v1/myap/blurb?userType=${role}`)
    return data
  } catch (err) {
    return {}
  }
})
