import { useMutation } from '@apollo/client'
import { saveDeletedNotificationMutation } from '../appsync/graphql/content'
import { updateNotifications } from '../appsync/actions/content'
import { formatAppsyncErrors } from '../appsync/utils'

const useDeleteNotificationMutation = ({ id, educationPeriod, onCompleted }) => {
  const [mutate, { loading, error: rawError = null, data: rawData = {} }] = useMutation(
    saveDeletedNotificationMutation,
    {
      variables: { id, educationPeriod },
      onCompleted,
      update(cache) {
        updateNotifications(cache, { id, educationPeriod })
      },
    }
  )
  const data = rawData?.saveDeletedNotification ?? false
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, loading, error }
}

export default useDeleteNotificationMutation
