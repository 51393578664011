import { useEffect } from 'react'
import { CardSet } from '@myap/ui-library'
import Banner from '../_common/banner/Banner'
import ProfessionalTimeline from '../professional/timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'
import Resources from '../_common/content/resources/Resources'
import { filterByCourseCategory, filterByTestCd } from '../../components/_common/content/utils'
import { getCMSTimelineEvents } from '../../rest/cms'

import styles from '../../sass/content.module.scss'

export default () => {
  const [events, setEvents] = useState([])
  const userRole = 'professional'

  useEffect(() => {
    const fetch = async () => {
      const data = await getCMSTimelineEvents(userRole)
      const filtered = filterByTestCd([], filterByCourseCategory([], data)).filter(
        f => !f.systemDate
      )
      setEvents(filtered)
    }

    fetch()
  }, [])

  return (
    <>
      <Banner />
      <TimelineTeaser events={events} />
      <div className="container cb-margin-top-48">
        <Resources
          userRole={userRole}
          showLoading={true}
          Component={({
            faqs = {},
            resourceLinks = {},
            ['teacher-callout']: teacherCallout,
            ['nonteacher-callout']: nonTeacherCallout,
          }) => (
            <CardSet
              cards={[
                {
                  id: 'unknownTeacher',
                  title: teacherCallout?.title,
                  content: (
                    <div
                      className="cb-align-center"
                      dangerouslySetInnerHTML={{ __html: teacherCallout?.content }}
                    />
                  ),
                  action: (
                    <a href={teacherCallout?.button?.url} className="cb-btn cb-btn-light">
                      {teacherCallout?.button?.title}
                    </a>
                  ),
                  cta: true,
                  className: 'cb-blue2-bg cb-white-color',
                },
                {
                  id: 'unknownNonTeacher',
                  title: nonTeacherCallout?.title,
                  content: (
                    <div
                      className="cb-align-center"
                      dangerouslySetInnerHTML={{ __html: nonTeacherCallout?.content }}
                    />
                  ),
                  action: (
                    <a href={nonTeacherCallout?.button?.url} className="cb-btn cb-btn-light">
                      {nonTeacherCallout?.button?.title}
                    </a>
                  ),
                  cta: true,
                  className: 'cb-purple1-bg cb-white-color',
                },
              ]}
            />
          )}
        />
      </div>
      <ProfessionalTimeline events={events} />
    </>
  )
}
