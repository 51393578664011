import { CardSet } from '@myap/ui-library'
import JoinCourse from '../joincourse/JoinCourse'
import ItemList from '../../_common/apricot/ItemList'
import Resources from '../../_common/content/resources/Resources'

export default ({ enrollments, preview, hideJoinCourse }) => {
  return (
    <Resources
      userRole="student"
      hideJoinCourse={hideJoinCourse}
      courses={enrollments}
      showLoading={!enrollments.length}
      Component={({ joinCourse, welcome, examScores, faqs }) => {
        const buttonProps = enrollments.length ? joinCourse : welcome
        const cards = []
        if (examScores) {
          cards.push({
            id: 'examScoresCard',
            title: examScores.title,
            content: (
              <ItemList content={examScores.content} items={examScores.links} preview={preview} />
            ),
          })
        }
        if (faqs) {
          cards.push({
            id: 'faqsCard',
            title: faqs.title,
            content: <ItemList items={faqs.links} preview={preview} />,
          })
        }
        return (
          <>
            <div className="row">
              {!hideJoinCourse ? (
                <div className="col-xs-12 cb-margin-bottom-24">
                  <JoinCourse {...buttonProps} preview={preview} />
                </div>
              ) : null}
            </div>
            <CardSet cards={cards} columnClasses="col-xs-12 cb-margin-bottom-24" />
          </>
        )
      }}
    />
  )
}
