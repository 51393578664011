import get from 'lodash/get'
import { Error, getLoginToken } from '@myap/ui-library'
import MainApp from '../apps/MainApp'
import useCatapultAuth from '../../hooks/useCatapultAuth'

const RedirectMsg = ({ url }) => (
  <div className="container">
    <h1 style={{ marginTop: 50 }}>It looks like you might be in the wrong place.</h1>
    <p>
      For the best My AP experience, please visit <a href={url}>{url}</a>.
    </p>
  </div>
)

export default () => {
  const { isAuthed, basicProfile, timeError, loaded, loading, error } = useCatapultAuth()
  const toggle = get(window, 'myap.widget.toggle')
  const { apstudentsUrl, apcentralUrl } = get(window, 'myap.profile')

  // If there is a cb_login cookie and user is authed, then toggle widget ON
  if (Boolean(getLoginToken()) === isAuthed) {
    console.log('Toggle login containers off, display widget, isAuthed:', isAuthed)
    toggle(!isAuthed)
  }

  if (error)
    return (
      <Error
        title="Temporarily Unavailable"
        message={timeError || 'Please try again later.'}
        useWrapper={true}
      />
    )

  if (!isAuthed) {
    console.error('*** isAuthed failed:', isAuthed)
    return null
  }

  console.log('Basic Profile:', basicProfile)

  // If currently authed user is not a student but viewing apstudents, redirect to apcentral
  if (!basicProfile.isStudent && /apstudent/.test(window.location.origin))
    return <RedirectMsg url={apcentralUrl} />
  // If currently authed user is not a professional but viewing apcentra, redirect to apstudents
  if (!basicProfile.isProfessional && /apcentral/.test(window.location.origin))
    return <RedirectMsg url={apstudentsUrl} />

  console.log('*** Start MainApp')
  return <MainApp />
}
