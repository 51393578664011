import { useApolloClient } from '@apollo/client'
import { Modal, BlackButton, PrimaryButton, Icon } from '@cb/apricot-react'
import { isExamOnly } from '@myap/metadata'
import { Address, Error } from '@myap/ui-library'
import useStudentJoinCourseDetailsQuery from '../../hooks/useStudentJoinCourseDetailsQuery'
import useStudentSectionDetailsQuery from '../../hooks/useStudentSectionDetailsQuery'
import { setJoinCourseDetails } from '../../../../appsync/actions/student'
import { useAnalyticsStudentConfirmJoinCodeEvent } from '../../../../hooks/analyticsHooks'
import * as c from './constants'

import styles from '../joincourse.module.scss'

function ConfirmMessage({ isTransfer, isMove, preAPSection, examOnly }) {
  return isTransfer ? (
    <p>
      By using this transfer code you will be dropped from the AP course at any other school where
      you are enrolled and any associated exam orders will be removed. You will not be subject to
      fees for changes to your AP exam order as a result of transferring schools. Do you want to
      proceed?
    </p>
  ) : isMove ? (
    preAPSection ? (
      <p>
        By using this join code you will be moving Pre-AP class sections. Do you want to proceed?
      </p>
    ) : (
      <p>
        By using this join code you will be moving AP class sections. Your exam registration status
        will not be changed. Do you want to proceed?
      </p>
    )
  ) : (
    <p>Are you taking this {examOnly ? 'exam' : 'course'}?</p>
  )
}

function ConfirmCode(props) {
  const {
    sectionName,
    courseName,
    orgName,
    address,
    joinCode,
    transferCode,
    isTransfer,
    isMove,
    teachers,
    preAPSection,
    error,
  } = props
  const enrollmentCode = isTransfer ? transferCode : joinCode

  const examOnly = isExamOnly(props)
  const numOfTeachers = teachers.length

  return (
    <div className={styles['confirm-code']}>
      <div className={styles['code-box']}>
        {enrollmentCode}
        <Icon name="check" color="green1" />
      </div>
      <h5 className="roboto">
        {courseName} {examOnly ? <em className="cb-base-font">(exam only)</em> : null}
      </h5>
      <div className="row">
        <div className="col-xs-4">
          <strong>School</strong>
        </div>
        <div className="col-xs-8">
          {orgName}
          <br />
          <Address {...address} />
        </div>
      </div>
      {numOfTeachers ? (
        <div className="row">
          <div className="col-xs-4">
            <strong>Teacher{numOfTeachers > 1 ? 's' : ''}</strong>
          </div>
          <div className="col-xs-8">{teachers.join('; ')}</div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-xs-4">
          <strong>Section</strong>
        </div>
        <div className="col-xs-8">{sectionName}</div>
      </div>
      {error ? (
        <Error message={error} />
      ) : (
        <ConfirmMessage
          isTransfer={isTransfer}
          isMove={isMove}
          preAPSection={preAPSection}
          examOnly={examOnly}
        />
      )}
    </div>
  )
}

function FooterActions({
  joinCourseData,
  languageSurveyRequired,
  enrollments,
  isMove,
  enrollmentId,
  changeStep,
}) {
  const client = useApolloClient()
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <BlackButton
        className="cb-margin-right-16"
        onClick={() => {
          changeStep(c.EXIT)
        }}
      >
        No
      </BlackButton>
      <PrimaryButton
        onClick={() => {
          const step = languageSurveyRequired
            ? c.SURVEY
            : !enrollments.length
            ? c.INFORMATION
            : c.SAVE
          setJoinCourseDetails(client, { ...joinCourseData, isMove, enrollmentId })
          changeStep(step)
        }}
      >
        Yes
      </PrimaryButton>
    </div>
  )
}

// 3 actions
// show registration
// show language
// enroll
function ConfirmCodeStep({ changeStep, enrollments }) {
  // Get join course details
  const { data: joinCourseData } = useStudentJoinCourseDetailsQuery({})
  // Get section details
  const { data: sectionDetailsData } = useStudentSectionDetailsQuery({
    enrollmentCode: joinCourseData?.enrollmentCode,
  })

  const { languageSurveyRequired, transferCode, enrollmentCode, testCd, orgId } = sectionDetailsData
  const isTransfer = transferCode === enrollmentCode
  // In order to be considered a move, student must be enrolled in the same course at the same school
  const existingEnrollment = enrollments.findIndex(e => e.testCd === testCd && e.orgId === orgId)
  const isMove = !isTransfer && existingEnrollment >= 0
  const enrollmentId = isMove ? enrollments[existingEnrollment].enrollmentId : null

  useAnalyticsStudentConfirmJoinCodeEvent()

  return (
    <Modal
      title={`Enter ${isTransfer ? 'Transfer' : 'Join'} Code to Enroll`}
      open={true}
      openAnimation={true}
      closeAnimation={true}
      footer={
        <FooterActions
          joinCourseData={joinCourseData}
          languageSurveyRequired={languageSurveyRequired}
          enrollments={enrollments}
          isMove={isMove}
          enrollmentId={enrollmentId}
          changeStep={changeStep}
        />
      }
      shadowRoot
      modalNode
    >
      <ConfirmCode {...sectionDetailsData} isTransfer={isTransfer} isMove={isMove} />
    </Modal>
  )
}

export default ConfirmCodeStep
