import { useLazyQuery } from '@apollo/client'
import { sectionDetailsQuery } from '../../../appsync/graphql/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentSectionDetailsLazyQuery = () => {
  const [
    getStudentSectionDetailsLazyQuery,
    { called, loading, error: rawError = null, data: rawData = {} },
  ] = useLazyQuery(sectionDetailsQuery)
  const data = rawData?.getSectionDetails ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { getStudentSectionDetailsLazyQuery, data, called, loading, error }
}

export default useStudentSectionDetailsLazyQuery
