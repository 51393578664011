// polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'unfetch/polyfill/index'
import { createRoot } from 'react-dom/client'
import set from 'lodash/set'
import get from 'lodash/get'
import { setEnvironment, getLoginToken } from '@myap/ui-library'
import Widget from './components/widget/Widget'

let appContainer = null
let root = null

const eventPolyfill = () => {
  if (typeof window.CustomEvent === 'function') return false

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
}

const destroy = () => {
  if (appContainer) {
    console.error('*** Destroy app container')
    root.unmount()
  }
}

const getContainers = container => {
  return container
    ? container.split(', ').reduce((acc, c) => [...acc, ...document.querySelectorAll(c)], [])
    : []
}

const SHOW_EVENT = 'showMyAPWidget'
const HIDE_EVENT = 'hideMyAPWidget'
const toggleShowEvent = new CustomEvent(SHOW_EVENT, { bubbles: true })
const toggleHideEvent = new CustomEvent(HIDE_EVENT, { bubbles: true })

// toggle(true) will hide MyAP widget and display the logged out containers
const toggle = show => {
  console.log('*** toggle logout containers:', show)
  const containers = get(window, 'myap.widget.options.logoutContainers')
  appContainer.dispatchEvent(show ? toggleHideEvent : toggleShowEvent)
  containers.forEach(c => (c.style.display = show ? 'block' : 'none'))
}

// init's container parameter can accept either a string that names a DOM element or
// an actual DOM element
const init = (container, { env, appId = 366, logoutContainer }) => {
  const isLoggedIn = getLoginToken()

  console.log('*** cb_login:', isLoggedIn)

  if (typeof container === 'string') {
    appContainer = document.querySelector(container)
  } else if (container instanceof Element) {
    appContainer = container
  } else {
    console.error('Error: DOM element not provided')
  }

  eventPolyfill()

  set(window, 'myap.widget.options.logoutContainers', getContainers(logoutContainer))
  console.log(`*** setEnvironment - env: ${env}, appId: ${appId}`)
  setEnvironment(env, appId)

  if (isLoggedIn) toggle(false)

  setTimeout(() => {
    console.log('### Delay')
    root = createRoot(appContainer)
    root.render(<Widget />)
  }, 2000)
  // root = createRoot(appContainer)
  // root.render(<Widget />)
}

set(window, 'myap.widget', {
  init,
  destroy,
  toggle,
  events: { show: SHOW_EVENT, hide: HIDE_EVENT },
})
