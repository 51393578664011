import { Icon } from '@cb/apricot-react'
import { formatDate, DATETIME_FORMATS, profile, sortDates } from '@myap/ui-library'
import links from '../../_common/coursecard/links'
import ToggleInactiveLink from '../../_common/actions/ToggleInactiveLink'

import styles from './buttonlinks.module.scss'

export default ({ course, preview, examDates = [] }) => {
  const {
    testCd,
    isStudioArt,
    isDigitalPortfolio,
    isPreAP /*, digitalApplicable, earliestExamDate, noEndOfCourseExam*/,
    disableCourseExamLink,
  } = course
  const { apstudentsUrl, aproUrl } = profile()
  const urls = links(apstudentsUrl)
  //const sortedDates = sortDates(examDates.map(e => ({ date: e.examDate })))
  //const { date = earliestExamDate } = sortedDates[0] || {}

  if (!isPreAP)
    return (
      <div className={styles['event-links']}>
        {/* {noEndOfCourseExam ? (
          <div>
            <strong>See Submission Deadline</strong>
          </div>
        ) : date ? (
          <div>
            <i className="cb-glyph cb-cal-full" aria-hidden={true} /> <strong>Exam Day</strong> {formatDate(date, DATETIME_FORMATS.longMonthDayYear)}
          </div>
        ) : null}
        {digitalApplicable ? (
          <ToggleInactiveLink inactive={preview} href={`${aproUrl}/digital-exam-readiness?testCd=${testCd}`} className={styles['event-link']}>
            Digital Exam{' '}
            <span className={styles['no-wrap']}>
              Readiness <span className="cb-glyph cb-east" />
            </span>
          </ToggleInactiveLink>
        ) : null} */}
        {!disableCourseExamLink ? (
          <ToggleInactiveLink
            inactive={preview}
            href={urls[testCd]}
            className={styles['event-link']}
          >
            Go to Course & Exam{' '}
            <span className={styles['no-wrap']}>
              pages <Icon name="east" />
            </span>
          </ToggleInactiveLink>
        ) : null}
        {isDigitalPortfolio || isStudioArt ? (
          <ToggleInactiveLink
            inactive={preview}
            href="https://digitalportfolio.collegeboard.org/"
            className={`${styles['event-link']}`}
          >
            Go to Digital{' '}
            <span className={styles['no-wrap']}>
              Portfolio <Icon name="east" />
            </span>
          </ToggleInactiveLink>
        ) : null}
      </div>
    )

  return null
}
