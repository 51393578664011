import { CBModal } from '@cb/apricot'

export const getShadowRootNode = () => {
  const externalShadowRootNode = document.getElementById('spa-widget-myap')
  const rootNode = externalShadowRootNode
    ? externalShadowRootNode.shadowRoot.querySelector('.myap-widget-shadow #myap-root')
    : document.getElementById('myap-root')

  return rootNode
}

export const adjustModalHeight = modalSelector => {
  const shadowRootNode = getShadowRootNode()
  // console.log('shadowRootNode', shadowRootNode)
  const elem = shadowRootNode.querySelector(modalSelector) // Apricot Modal does not support passing refs
  // const elem = document.getElementById(ID) // Apricot Modal does not support passing refs
  // console.log('rootNode+elem', elem)
  CBModal({ elem, shadowRoot: true }).adjustHeight()
}
