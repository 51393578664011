import { Icon } from '@cb/apricot-react'
import ToggleInactiveLink from '../actions/ToggleInactiveLink'
import styles from './horizontallinklist.module.scss'

export default ({ items = [], withinBanner = false }) => (
  <ul
    className={`${styles['link-list']}${
      withinBanner ? ` ${styles['within-banner']}` : ''
    } cb-align-right cb-xs-align-left`}
  >
    {items.map(({ href, onClick = null, title = '', newWindow = false }, i) => {
      const numOfItems = items.length
      const showSeparator = numOfItems > 1 && i !== numOfItems - 1

      return (
        <li key={i}>
          <ToggleInactiveLink
            href={href}
            inactive={href === '#'}
            onClick={onClick}
            target={newWindow ? '_blank' : null}
            light={withinBanner}
          >
            <span>{title}</span>
            <Icon name="east" />
          </ToggleInactiveLink>
          {showSeparator ? <span aria-hidden={true} /> : null}
        </li>
      )
    })}
  </ul>
)
