import { CBRegExpValidation } from '@cb/apricot'

/**
 * Checks value consists of only letters, hypens, spaces, apostrophes and first and last characters are letters
 */
export const validatePreferredFirstName = value =>
  /^(?![- '])(?!.*[- ']$)[-A-Za-z ']*$/.test(value) || !value
    ? undefined
    : 'Error: Please enter a valid preferred first name (no numbers or symbols).'

/**
 * Checks value consists of only letters, numbers, asteriks, parentheses (open and closed), hypens, spaces and forward slashes
 */
export const validateGenderComment = value =>
  /^[a-zA-Z0-9*()\-\s\/]+$/.test(value) || !value
    ? undefined
    : 'Error: Comment in this field contains invalid characters.'

/**
 * Checks the following:
 *   - Only one @ symbol
 *   - Username (everything before @) validations:
 *      - At least two characters
 *      - First character is alphanumeric and the rest can include: ! # $ % & ' * + - / = ? ^ _ ` . { | } ~
 *      - Does not end with a period
 *   - Domain validations:
 *      - Allowed characters are alphanumeric and hypens
 *      - Last character is alphanumeric
 *      - Top-level domain (characters after dot) is at least 2 characters and max of 20
 *      - At least two characters are alphanumeric
 */
export const validateEmail = value => {
  const [username, domain, ...unknowns] = value?.split('@')
  const isProperFormat = username && domain && unknowns.length === 0
  const isProperUsername = /^(?!.*[.]$)[a-zA-Z0-9][a-zA-Z0-9!#$%&'*+\-\/=?^_`.{|}~]+$/.test(
    username
  )
  const isProperDomain =
    /^(?!.*[-]$)(([a-zA-Z0-9\-]+)\.)+[a-zA-Z0-9\-]{2,20}$/.test(domain) &&
    domain.match(/[a-zA-Z0-9]/g)?.length > 1

  return isProperFormat && isProperUsername && isProperDomain
    ? undefined
    : 'Error: Please enter a valid email address.'
}

/**
 * Checks that the two email fields have the same value.
 * The comparator should be the original email entered and value is the confirmation value
 */
export const validateConfirmEmail = comparator => value => {
  return comparator.toLowerCase() === value.toLowerCase()
    ? undefined
    : `Error: Your email addresses don't match, please retype.`
}

/**
 * Checks value consists of only letters, numbers, periods, hypens, spaces, pound signs and forward slashes
 */
export const validateAddressLine = value =>
  /^[a-zA-Z0-9#:'@\.\-\/ ]*$/.test(value) || !value
    ? undefined
    : 'Error: Please enter a valid mailing address.'

/**
 * Checks value consists of only letters, periods, hypens, spaces, pound signs
 */
export const validateCity = value =>
  /^[a-zA-Z0-9#()/'\.\- ]*$/.test(value) || !value ? undefined : 'Error: Please enter a valid city.'

/**
 * Checks value consists of only letters, numbers, hypens, spaces, and forward slashes
 */
export const validatePostalCode = isDomestic => value => {
  const errorMsg = 'Error: Please enter a valid mailing address.'
  if (isDomestic) {
    return CBRegExpValidation.zipCode(value) || !value ? undefined : errorMsg
  }
  return /^[a-zA-Z0-9\-\s\/]*$/.test(value) || !value ? undefined : errorMsg
}
