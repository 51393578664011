import { createRoot } from 'react-dom/client'
import CBModal from '@cb/apricot/CBModal'
import { iam } from '@myap/ui-library'
import Provider from '../apollo/Provider'
import { getShadowRootNode } from '../../../utilities/dom'
// import styles from './_apricotoverrides.module.scss'

/**
 * This implementation of CBModal should NOT be in active use.
 * Instead, use the Modal component from apricot-react
 ***
 * NOTE: The OpenModal() function below *IS* still in use for the sake of
 * properly adding modals to DOM/ShadowDOM
 **/

export const Modal = props => {
  const {
    title,
    isDismissable = true,
    buttons = [],
    body,
    className = '',
    trigger,
    elem, // Modal element ID
    shouldCloseModal = false,
    onCloseAction,
    style = {},
    onClose,
    focusElem, // Focus this element when modal is displayed
    loading = false, // Use to indicate when modal content has finished loading to trigger a CBModal.adjustHeight() call
    children,
    analytics = true,
  } = props
  const modalRef = useRef(null)
  const {
    getEventBus,
    events: { Logout },
  } = iam()
  const bus = getEventBus()
  const [closeModal, setCloseModal] = useState(shouldCloseModal)

  useEffect(() => {
    CBModal({
      elem: modalRef.current,
      trigger,
      escClose: isDismissable,
      disableScroll: true,
      focusElem,
      analytics,
      analyticsTitle: title,
      // awaitOpenAnimation: false,
      // awaitCloseAnimation: false,
    }).show()
    bus.on(Logout, () => setCloseModal(true))
    return () => {
      bus.off(Logout, setCloseModal(true))
    }
  }, [])

  useEffect(() => {
    if (closeModal || shouldCloseModal) {
      CBModal({ elem: modalRef.current }).close({ onClose })
      onCloseAction && onCloseAction()
    }
  }, [closeModal, shouldCloseModal])

  useEffect(() => {
    if (CBModal && modalRef.current && modalRef.current.classList.contains('cb-open') && !loading) {
      CBModal({ elem: modalRef.current, trigger }).adjustHeight()
    }
  }, [children, loading])

  return (
    <div className="cb-modal" id={elem} ref={modalRef}>
      <div className="cb-modal-overlay" tabIndex="-1" data-cb-modal-close={isDismissable}>
        <div
          className={`cb-modal-container ${className}`}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`modal${elem}Title`}
          style={style}
        >
          {title || isDismissable ? (
            <div className={`cb-modal-header${isDismissable ? ' cb-modal-has-close' : ''}`}>
              {title ? (
                <h2 className="cb-modal-title" id={`modal${elem}Title`}>
                  {title}
                </h2>
              ) : null}
              {isDismissable ? (
                <button
                  type="button"
                  className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                  aria-describedby={`modal${elem}Title`}
                  data-cb-modal-close
                >
                  <span className="cb-icon cb-x-mark" aria-hidden="true" />
                  <span className="sr-only">Close</span>
                </button>
              ) : null}
            </div>
          ) : null}
          <div className={`cb-modal-content${title ? '' : ' cb-no-header'}`}>
            {body ?? children}
          </div>
          <div className="cb-modal-footer">
            {buttons.length ? (
              <div className="cb-btn-row">
                {buttons.map((b, i) => {
                  return b.component ? (
                    <b.component key={i} />
                  ) : (
                    <button
                      key={i}
                      type="button"
                      disabled={b.disabled}
                      onClick={b.click}
                      className={`cb-btn cb-btn-sm cb-btn-${
                        b.isLink ? 'naked' : b.primary ? 'primary' : 'secondary'
                      }${b.busy ? ' cb-btn-pulse' : ''}`}
                      data-cb-modal-close={b.dismiss}
                    >
                      {b.busy ? <span aria-live="polite">Loading</span> : b.title}
                      {b.busy ? (
                        <div className="cb-btn-pulse-dots">
                          <div className="cb-btn-dots" />
                        </div>
                      ) : null}
                    </button>
                  )
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

let appendedElem = null
export const OpenModal = (Component, props, trigger) => {
  // const externalShadowRootNode = document.getElementById('spa-widget-myap')
  // const rootNode = externalShadowRootNode
  //   ? externalShadowRootNode.shadowRoot.querySelector('.myap-widget-shadow #myap-root')
  //   : document.getElementById('myap-root')
  const shadowRootNode = getShadowRootNode()
  const modalContainer = document.createElement('div')
  modalContainer.setAttribute('id', 'modal-root')

  if (appendedElem) shadowRootNode.removeChild(appendedElem)

  appendedElem = modalContainer
  const modalRoot = createRoot(shadowRootNode.appendChild(appendedElem))

  modalRoot.render(
    <Provider>
      <Component {...props} trigger={trigger} />
    </Provider>
  )
}
