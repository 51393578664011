import {
  SimpleAccordion,
  SimpleAccordionItem,
  SimpleAccordionHeader,
  SimpleAccordionPanel,
} from '@cb/apricot-react'

function CourseAccordion({ Title, Body, courses = [], accordionId }) {
  const panelBodyMargin = { margin: '0 0 1.5em 0' }
  const panels = courses.map(d => {
    return {
      panelId: `${d.testCd}-${accordionId}`,
      title: Title ? <Title {...d} /> : d.courseName,
      body: Body ? <Body {...d} /> : null,
    }
  })

  return (
    <SimpleAccordion single={false}>
      {panels.map(({ panelId, title, body }) => (
        <SimpleAccordionItem key={panelId}>
          <SimpleAccordionHeader>{title}</SimpleAccordionHeader>
          <SimpleAccordionPanel>{body}</SimpleAccordionPanel>
        </SimpleAccordionItem>
      ))}
    </SimpleAccordion>
  )
}

export default CourseAccordion
