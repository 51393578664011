import { Icon } from '@cb/apricot-react'
import ToggleInactiveLink from '../../_common/actions/ToggleInactiveLink'
import Resources from '../../_common/content/resources/Resources'

import styles from './resources.module.scss'

const ResourceLinks = ({ listTitle = '', items, preview }) => {
  const itemCount = items.length
  return (
    <div className={styles['resource-group']}>
      {listTitle ? <h3 className="cb-roboto-bold">{listTitle}</h3> : null}
      <ul className={styles['resource-links']}>
        {items.map((item, i) => (
          <li
            key={`${item.icon}-${i}`}
            className={`${styles.resource} ${i === itemCount - 1 ? styles.last : ''}`}
          >
            <ToggleInactiveLink inactive={preview} href={item.link[0].url}>
              {item.link[0].title}
            </ToggleInactiveLink>
            <Icon className={styles.icon} name="east" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ({ courses, userRole, id, preview }) => (
  <Resources
    userRole={userRole}
    courses={courses}
    Component={({ faqs = {}, resourceLinks = {} }) => {
      return (
        <div id={id}>
          {resourceLinks.links ? (
            <ResourceLinks
              items={resourceLinks.links}
              listTitle="Additional Teacher Resources"
              preview={preview}
            />
          ) : null}
          {faqs.links ? (
            <ResourceLinks listTitle={faqs.title} items={faqs.links} preview={preview} />
          ) : null}
        </div>
      )
    }}
  />
)
