import { BareModal } from '@cb/apricot-react'
import { useId } from 'react'
import RegistrationForm from '../profileandreg/RegistrationForm'
import { adjustModalHeight } from '../../../utilities/dom'
import { NONE } from './constants'
import Header from './Header'

const ID = 'studentRegistrationModal'

function StudentRegistrationModal({ changeStep }) {
  const [open, setOpen] = useState(true)
  const useAnimation = true
  const modalHeaderId = useId()
  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <BareModal
        modalId={ID}
        open={open}
        openAnimation={useAnimation}
        closeAnimation={useAnimation}
        clickOverlayToClose={false}
        escClose={false}
        shadowRoot
        modalNode
    >
      <Header headerId={modalHeaderId} />
      <RegistrationForm
        withHeader={false}
        changeStep={changeStep}
        setOpen={setOpen}
        nextStep={NONE}
        isDismissable={false}
        adjustHeight={adjustHeight}
      />
    </BareModal>
  )
}

StudentRegistrationModal.displayName = 'Student Registration Modal'

export default StudentRegistrationModal
