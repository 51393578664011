/**
 *  Description:
 *  Custom hook for getting previous value
 *
 *  Usage:
 *  const prevValue = usePrevious(value)
 *
 *  Explained:
 *  https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 **/

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
