import { useQuery } from '@apollo/client'
import { isEmpty } from '@myap/ui-library'
import { districtDataQuery } from '../../../appsync/graphql/professional'
import { updateQueryDistrictCourse } from '../../../appsync/actions/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictCoursesQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(districtDataQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = rawData?.getDistrictData ?? []
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useDistrictCoursesQuery
