import { useQuery } from '@apollo/client'
import { isEmpty } from '@myap/ui-library'
import { districtStatusQuery } from '../../../appsync/graphql/professional'
import { updateQueryDistrictStatus } from '../../../appsync/actions/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictStatusQuery = ({ orgId, educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(districtStatusQuery, {
    variables: { orgId, educationPeriod },
    skip: !orgId || !educationPeriod || otherSkipConditions,
  })
  const data = {
    ...(rawData?.getDistrictStatus?.data ?? {}),
    lastUpdated: rawData?.getDistrictStatus?.lastUpdated,
  }
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useDistrictStatusQuery
