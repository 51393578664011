import { formatDateTime } from '@myap/ui-library'
import styles from './lastupdated.module.scss'

export default ({ lastUpdated }) =>
  lastUpdated ? (
    <div className={styles['last-updated']}>
      <p className="cb-align-right cb-margin-top-8">
        Last Updated: {formatDateTime(lastUpdated, 'MM/dd/yyyy h:mm a z')}
      </p>
    </div>
  ) : null
