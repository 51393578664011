import { PrimaryButton, Button, Spinner } from '@cb/apricot-react'
import { formatDate, isDateBefore, isDateAfter, DATETIME_FORMATS, isEmpty } from '@myap/ui-library'
import { isDistrictAdmin } from '@myap/metadata'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useDistrictInvoicesQuery from '../hooks/useDistrictInvoicesQuery'
import useDistrictInvoiceDownloadUrlQuery from '../hooks/useDistrictInvoiceDownloadUrlQuery'

function ErrorMsg({ error }) {
  return (
    <span className="cb-validation-msg" aria-live="polite" tabIndex="-1">
      Error: {error}
    </span>
  )
}

function DownloadInvoice({ orgId, currentEducationPeriod, invoiceEducationPeriod }) {
  const [downloadUrl, setDownloadUrl] = useState(null)
  const [clickedDownload, setClickedDownload] = useState(false)

  // Get district invoice download URL
  const {
    getDownloadUrl,
    data: invoiceDownloadUrl,
    loading,
    error,
    refetch,
  } = useDistrictInvoiceDownloadUrlQuery({
    orgId,
    educationPeriod: invoiceEducationPeriod,
  })

  useEffect(() => {
    if (!(loading || error || isEmpty(invoiceDownloadUrl))) {
      setDownloadUrl(invoiceDownloadUrl)
    }
  }, [loading, invoiceDownloadUrl, error])

  useEffect(() => {
    if (clickedDownload && downloadUrl) {
      window.open(downloadUrl)
      setClickedDownload(false)
      setDownloadUrl(null)
    }
  }, [clickedDownload, downloadUrl])

  const handleClick = () => {
    if (!isEmpty(invoiceDownloadUrl)) {
      refetch()
    } else {
      getDownloadUrl()
    }
    setClickedDownload(true)
  }

  if (invoiceEducationPeriod === currentEducationPeriod) {
    return (
      <>
        <PrimaryButton icon="download" loading={loading} onClick={handleClick}>
          View your invoice
        </PrimaryButton>
        {error ? <ErrorMsg error={error} /> : null}
      </>
    )
  }

  return (
    <>
      <Button icon="download" loading={loading} onClick={handleClick}>
        View last year&#39;s invoice
      </Button>
      {error ? <ErrorMsg error={error} /> : null}
    </>
  )
}

function DistrictInvoice() {
  // Get user details
  const {
    userDetails: { selectedOrgId: orgId, roles },
    educationPeriod: { code: currentEducationPeriod },
    systemDates,
  } = useUserSettingsQuery()

  // Find the role for the selectedOrgId
  const userRole = roles.find(role => role.orgId === orgId)?.role

  // Get district current year invoice data
  const { data: invoiceData, loading } = useDistrictInvoicesQuery({
    orgId,
  })

  const today = new Date()
  const districtFundingLockDate = systemDates?.deadlines?.districtFundingLockDate
  const isAfterDistrictFundingLockDate = isDateAfter(today, districtFundingLockDate)

  if (loading) {
    if (isDistrictAdmin(userRole)) {
      return <Spinner size="24" />
    }

    return (
      <span
        className="cb-loader-shimmer cb-loader-effect cb-margin-top-24"
        style={{ display: 'inline-block', width: '300px', height: '40px' }}
      />
    )
  }
  const invoiceType = invoiceData?.data?.invoiceType;
  if (invoiceType === 'DISTRICT' || invoiceType === 'CNTRL_BILL_DIST') {
    // If after district funding lock date and before invoice is available
    if (
      !isDistrictAdmin(userRole) &&
      isAfterDistrictFundingLockDate &&
      invoiceData?.data?.invoiceAvailableDate &&
      isDateBefore(today, invoiceData?.data?.invoiceAvailableDate)
    ) {
      return (
        <div className="row cb-margin-top-24">
          <div className="col-xs-12">
            <p>
              Your invoice will be ready on{' '}
              {formatDate(
                invoiceData?.data?.invoiceAvailableDate,
                DATETIME_FORMATS.longMonthDayYear
              )}
            </p>
          </div>
        </div>
      )
    }

    // else, if after district funding closed and invoice data returned is for current education
    // period and invoice PDF has been generated
    if (
      isAfterDistrictFundingLockDate &&
      invoiceData?.educationPeriodCd === currentEducationPeriod &&
      invoiceData?.data?.invoicePDFGenerated
    ) {
      return (
        <div className={`row${!isDistrictAdmin(userRole) ? ' cb-margin-top-24' : ''}`}>
          <div className="col-xs-12">
            {!isDistrictAdmin(userRole) ? (
              <p className="cb-margin-bottom-16">Your district&#39;s invoice is now available</p>
            ) : null}
            <DownloadInvoice
              orgId={orgId}
              invoiceEducationPeriod={currentEducationPeriod}
              currentEducationPeriod={currentEducationPeriod}
            />
          </div>
        </div>
      )
    }

    // else, it's previous year's invoice and invoice PDF has been generated
    if (
      invoiceData?.educationPeriodCd !== currentEducationPeriod &&
      invoiceData?.data?.invoicePDFGenerated
    ) {
      return (
        <div className={`row${!isDistrictAdmin(userRole) ? ' cb-margin-top-24' : ''}`}>
          <div className="col-xs-12">
            <DownloadInvoice
              orgId={orgId}
              invoiceEducationPeriod={invoiceData?.educationPeriodCd}
              currentEducationPeriod={currentEducationPeriod}
            />
          </div>
        </div>
      )
    }
  }

  // else, display nothing
  return null
}

export default DistrictInvoice
