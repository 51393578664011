import orderBy from 'lodash/orderBy'
import { useApolloClient } from '@apollo/client'
import { Icon, NakedButton } from '@cb/apricot-react'
import { profile, numberFormat } from '@myap/ui-library'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useDistrictCoursesQuery from '../../professional/hooks/useDistrictCoursesQuery'
import Table from '../apricot/Table'
import LastUpdated from '../lastupdated/LastUpdated'
import { setOrg } from '../../../appsync/actions/settings'

import styles from './courseaccordion.module.scss'

const getTotal = (arr, key) => numberFormat(arr.reduce((sum, item) => sum + (item[key] || 0), 0))
const changeToAdminView = (client, orgId) => setOrg(client, orgId)

function HeaderLink({ title, link }) {
  return (
    <a href={link} className={styles['table-header-link']}>
      <span>{title}</span>
      <Icon name="east" />
    </a>
  )
}

function Totals({ schools, isPreAP }) {
  return (
    <tr className={styles.totals}>
      <th scope="row">District Total</th>
      <td>{getTotal(schools, 'studentsEnrolled')}</td>
      {!isPreAP ? <td>{getTotal(schools, 'studentsRegistered')}</td> : null}
      <td>{getTotal(schools, 'studentsActive')}</td>
    </tr>
  )
}

function SchoolTable({ testCd: tableTestCd }) {
  const client = useApolloClient()
  // Get user details
  const {
    userDetails: { selectedOrgId: orgId, roles },
    educationPeriod: { code: educationPeriod, isTransitionPeriod },
  } = useUserSettingsQuery()

  const districtHasAtLeastOneSchoolSetup = roles.some(
    r => r.orgId !== orgId && !r.needToCompletePartForm
  )
  const variables = { educationPeriod, orgId }

  // Get District courses data
  const { data: coursesData } = useDistrictCoursesQuery({
    ...variables,
    otherSkipConditions: isTransitionPeriod || !districtHasAtLeastOneSchoolSetup,
  })

  const { data: schoolData, lastUpdated } =
    coursesData.find(({ data }) => data?.testCd === tableTestCd) ?? {}
  const { schools, testCd, isPreAP } = schoolData ?? {}
  const { classroomUrl, aproUrl } = profile()
  const sortedSchools = orderBy(schools, ['orgName'], ['asc'])

  const BodyBuilder = () =>
    sortedSchools.map((d, i) => {
      const { studentsEnrolled, studentsRegistered, studentsActive, orgName, orgId } = d

      return (
        <tr key={`${orgId}_${testCd}_${i}`}>
          <th scope="row">
            <NakedButton onClick={() => changeToAdminView(client, orgId)}>{orgName}</NakedButton>
          </th>
          <td>{numberFormat(studentsEnrolled)}</td>
          {!isPreAP ? <td>{numberFormat(studentsRegistered)}</td> : null}
          <td>{numberFormat(studentsActive)}</td>
        </tr>
      )
    })

  const TIER_HEADER = [
    { title: 'Organizations', srOnly: true },
    {
      title: (
        <HeaderLink
          title="AP Registration & Ordering"
          link={`${aproUrl}/students?crse=${testCd}`}
        />
      ),
      colspan: isPreAP ? 1 : 2,
    },
    {
      title: !isPreAP ? (
        <HeaderLink
          title="AP Classroom Progress Dashboard"
          link={`${classroomUrl}/${testCd}/dashboard_redirect?educationPeriod=${educationPeriod}&districtId=${orgId}`}
        />
      ) : (
        <HeaderLink
          title="Pre-AP Classroom Usage Dashboard"
          link={`${classroomUrl}/${testCd}/reports/usage/assessment`}
        />
      ),
      colspan: 1,
    },
  ]

  const HEADER_ENROLL = { title: 'Student Enrollments' }
  const HEADER_REG = { title: 'Exam Registrations' }
  const HEADER_ACTIVE = { title: !isPreAP ? 'Students Active' : 'Students Accessed Course' }

  return sortedSchools.length ? (
    <>
      <Table
        tierHeader={TIER_HEADER}
        header={
          isPreAP ? [HEADER_ENROLL, HEADER_ACTIVE] : [HEADER_ENROLL, HEADER_REG, HEADER_ACTIVE]
        }
        tbody={
          <>
            <Totals schools={schools} isPreAP={isPreAP} />
            <BodyBuilder />
          </>
        }
      />
      <LastUpdated lastUpdated={lastUpdated} />
    </>
  ) : (
    <p>
      <em>No sections have been created for this course.</em>
    </p>
  )
}

export default SchoolTable
