import { setPersonId } from './settings'
import {
  studentEnrollmentsQuery,
  joinCourseDetailsQuery,
  studentInfoAndRegistrationQuery,
} from '../graphql/student'

export const setStudentEnrollments = ({ client, educationPeriod }) => {
  try {
    client.writeQuery({
      query: studentEnrollmentsQuery,
      variables: { code: educationPeriod },
      data: {
        getStudentEnrollments: {
          scoreSendData: {
            diCode: null,
            userPromptType: null,
          },
          courseEnrollments: [],
          __typename: 'studentEnrollments',
        },
      },
    })
  } catch (err) {
    console.info(err)
  }
}

export const updateStudentRegistration = ({ client, data, educationPeriod, refresh }) => {
  try {
    client.writeQuery({
      query: studentInfoAndRegistrationQuery,
      variables: { code: educationPeriod, isRegistration: true, isStudent: true, refresh },
      data: {
        getStudentInfoAndRegistration: {
          ...data,
          __typename: 'studentInfoAndRegistration',
        },
      },
    })
    setPersonId(client, data.studentId)
    return data
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const updateStudentInformation = ({ client, data, educationPeriod, refresh }) => {
  try {
    client.writeQuery({
      query: studentInfoAndRegistrationQuery,
      variables: { code: educationPeriod, isProfile: true, isStudent: true, refresh },
      data: {
        getStudentInfoAndRegistration: {
          ...data,
          __typename: 'studentInfoAndRegistration',
        },
      },
    })
    setPersonId(client, data.studentId)
    return data
  } catch (err) {
    console.info(err)
    return {}
  }
}

export const updateStudentEnrollments = ({ cache, response, key, educationPeriod }) => {
  const {
    data: {
      [key]: {
        courseEnrollments,
        scoreSendData: { diCode, userPromptType },
      },
    },
  } = response
  // console.log('updateStudentEnrollments (action) - enrollment complete, update cache')
  cache.writeQuery({
    query: studentEnrollmentsQuery,
    variables: { code: educationPeriod },
    data: {
      getStudentEnrollments: {
        scoreSendData: {
          diCode,
          userPromptType,
          __typename: 'scoreSend',
        },
        courseEnrollments,
        __typename: 'studentEnrollments',
      },
    },
  })
}

export const saveStudentScoreSend = ({ cache, response, educationPeriod }) => {
  const attributes = {
    query: studentEnrollmentsQuery,
    variables: { code: educationPeriod },
  }
  const {
    data: {
      saveStudentScoreSend: { diCode, userPromptType },
    },
  } = response

  if (saveStudentScoreSend) {
    const {
      getStudentEnrollments: { courseEnrollments },
    } = cache.readQuery(attributes)

    cache.writeQuery({
      ...attributes,
      data: {
        getStudentEnrollments: {
          scoreSendData: {
            diCode,
            userPromptType,
            __typename: 'scoreSend',
          },
          scoreSendSaved: true,
          courseEnrollments,
          __typename: 'studentEnrollments',
        },
      },
    })
  }
}

export const setJoinCourseDetails = (
  client,
  {
    enrollmentCode,
    sectionId,
    livedInLanguageCountry = null,
    languageRegularlySpoken = null,
    isMove = false,
    enrollmentId = null,
  }
) => {
  client.writeQuery({
    query: joinCourseDetailsQuery,
    data: {
      getJoinCourseDetails: {
        enrollmentCode,
        sectionId,
        livedInLanguageCountry,
        languageRegularlySpoken,
        isMove,
        enrollmentId,
        __typename: 'joinCourseDetails',
      },
    },
  })
}
