export default function Header({ headerId }) {
  return (
    <div className="cb-modal-header">
      <h2 className="cb-modal-title" id={`apricot_modal_header_${headerId}`}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="cb-margin-bottom-8">My AP Profile</div>
        </div>
      </h2>
    </div>
  )
}
