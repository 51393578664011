import { useQuery } from '@apollo/client'
import { sectionDetailsQuery } from '../../../appsync/graphql/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentSectionDetailsQuery = ({ enrollmentCode, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(sectionDetailsQuery, {
    variables: { enrollmentCode },
    skip: !enrollmentCode || otherSkipConditions,
  })
  const data = rawData?.getSectionDetails ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useStudentSectionDetailsQuery
