import { userPreferencesQuery } from '../graphql/content'

export const updateNotifications = (cache, variables) => {
  const key = 'getUserPreferences'
  const query = { query: userPreferencesQuery, variables }
  const { [key]: preferences = {} } = cache.readQuery(query)
  // preferences will be null if no notifications have been deleted in this educationPeriod
  const { deletedNotifications = [] } = preferences !== null ? preferences : {}

  cache.writeQuery({
    ...query,
    data: {
      [key]: {
        ...preferences,
        deletedNotifications: [...deletedNotifications, variables.id],
      },
    },
  })
}
