import { useQuery } from '@apollo/client'
import { studentEnrollmentsQuery } from '../../../appsync/graphql/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentEnrollmentsQuery = ({ educationPeriod, otherSkipConditions = false }) => {
  const {
    loading,
    error: rawError = null,
    data: rawData = {},
  } = useQuery(studentEnrollmentsQuery, {
    variables: { code: educationPeriod },
    skip: !educationPeriod || otherSkipConditions,
  })
  const enrollmentsData = rawData?.getStudentEnrollments ?? {}
  const data = { ...enrollmentsData, courseEnrollments: enrollmentsData?.courseEnrollments ?? [] }
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { data, loading, error }
}

export default useStudentEnrollmentsQuery
