import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentInfoAndRegistrationQuery from '../hooks/useStudentInfoAndRegistrationQuery'
import MissingRegInfoFlow from '.'

function StudentRegistrationNeeded() {
  // Get user details
  const {
    educationPeriod: { code: educationPeriod, hasEnrollments, isRegistered },
  } = useUserSettingsQuery()

  // Get student info and registration
  const { loading: loadingRegistration, data: studentInfoAndRegistrationData } =
    useStudentInfoAndRegistrationQuery({
      educationPeriod,
      otherSkipConditions: !hasEnrollments || !isRegistered,
    })

  const [openRegistrationModal, setOpenRegistrationModal] = useState(false)

  useEffect(() => {
    let isCurrent = true
    if (isCurrent && !loadingRegistration && studentInfoAndRegistrationData?.missingRegInfoInd) {
      setOpenRegistrationModal(true)
    }
    return () => {
      isCurrent = false
    }
  }, [loadingRegistration, JSON.stringify(studentInfoAndRegistrationData)])

  if (openRegistrationModal) {
    return <MissingRegInfoFlow />
  }

  return null
}

export default StudentRegistrationNeeded
