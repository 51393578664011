import { Icon } from '@cb/apricot-react'
import { profile } from '@myap/ui-library'

function GoToScoreSendsButton({ text, updateFunc }) {
  const { scoreSendUrl } = profile()
  return (
    <button
      type="button"
      className="cb-btn cb-btn-primary"
      onClick={async () => {
        if (updateFunc) {
          await updateFunc()
        }
        window.location.assign(scoreSendUrl)
      }}
    >
      {text ?? (
        <>
          Go to the&nbsp;<strong>Free Score Sends</strong>&nbsp;page
        </>
      )}{' '}
      <Icon name="east" />
    </button>
  )
}

export default GoToScoreSendsButton
