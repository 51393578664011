import { isEmpty, encryptID, fireAnalyticsEvent } from '@myap/ui-library'
import useUserSettingsQuery from './useUserSettingsQuery'
import useStudentInfoAndRegistrationQuery from '../components/student/hooks/useStudentInfoAndRegistrationQuery'
import { usePrevious } from './usePrevious'
import { PROFILE } from '../components/student/profileandreg/constants'
import { ON_PAGE_TOP, USER_PROMPT_MODAL_ACTION } from '../components/student/scoresend/constants'

const SET_TESTING = false
const DISABLE_EVENTS = false

const EVENT_STUDENT_REG = 'studentReg'
const EVENT_ENROLL_SUCCESS = 'courseEnroll'
const EVENT_EXAM_REG = 'examReg'

function useStudentInfo() {
  const [appUser, setAppUser] = useState({})

  // Get user details
  const {
    personId,
    educationPeriod: { code: educationPeriod, hasEnrollments, isRegistered },
  } = useUserSettingsQuery()

  // Get student info and registration
  const { loading: loadingRegistration, data: studentInfoAndRegistrationData } =
    useStudentInfoAndRegistrationQuery({
      educationPeriod,
      otherSkipConditions: !hasEnrollments || !isRegistered,
    })

  useEffect(() => {
    if (!loadingRegistration) {
      const orgData = hasEnrollments
        ? {
            org: {
              code: studentInfoAndRegistrationData?.attendingOrg?.orgId,
              name: studentInfoAndRegistrationData?.attendingOrg?.orgName,
            },
          }
        : undefined
      const grade = hasEnrollments
        ? { gradeLevel: studentInfoAndRegistrationData?.grade }
        : undefined

      setAppUser({ role: 'student', ...(orgData || {}), ...(grade || {}) })
    }
  }, [loadingRegistration])

  return {
    appUser,
    hasEnrollments,
    isRegistered,
    encryptedStudentId: encryptID(personId),
    epc: educationPeriod,
  }
}

export const useAnalyticsStudentDashboardEvent = () => {
  const { appUser, hasEnrollments, isRegistered } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'dashboard',
            view: hasEnrollments ? 'enrolled' : isRegistered ? 'not-enrolled' : 'no-reg',
            group: '',
          },
        },
      })
    }
  }, [appUser, hasEnrollments, isRegistered])

  return null
}

export const useAnalyticsStudentEnrollStep1Event = ({ submitError, joinCode }) => {
  const { appUser, hasEnrollments } = useStudentInfo()

  useEffect(() => {
    if (!submitError && !joinCode && !isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'enter-join-code',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser, submitError, joinCode])

  return null
}

export const useAnalyticsStudentEnrollStep1WrongCodeEvent = () => {
  const { appUser, hasEnrollments } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'wrong-join-code',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser])
}

export const useAnalyticsStudentEnrollStep1ErrorEvent = ({ submitError }) => {
  const { appUser, hasEnrollments } = useStudentInfo()
  const prevSubmitError = usePrevious(submitError)

  useEffect(() => {
    if (submitError && submitError !== prevSubmitError && !isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'join-code-error',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser, submitError])
}

export const useAnalyticsStudentConfirmJoinCodeEvent = () => {
  const { appUser, hasEnrollments } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'confirm-join-code',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser])

  return null
}

export const useAnalyticsStudentRegStep1Event = () => {
  const { appUser, hasEnrollments } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser: { role: 'student' },
          appPage: {
            domain: 'student',
            page: 'regform-step-1',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser])

  return null
}

export const useAnalyticsStudentRegStep2Event = () => {
  const { appUser, hasEnrollments } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser: { role: 'student' },
          appPage: {
            domain: 'student',
            page: 'regform-step-2',
            view: '',
            group: hasEnrollments ? 'enroll' : 'register',
          },
        },
      })
    }
  }, [appUser])

  return null
}

export const useAnalyticsStudentRegSuccessEvent = ({
  complete = false,
  enrollmentDetails,
  enrollments = [],
}) => {
  const { appUser, encryptedStudentId, epc } = useStudentInfo()

  useEffect(() => {
    if (complete && !isEmpty(appUser)) {
      const firstEnrollment = enrollments.length === 1
      const { joinCode } = enrollmentDetails
      const currentEnrollment = enrollments.find(enrollment => enrollment.joinCode === joinCode)
      const events = [EVENT_ENROLL_SUCCESS]
      if (firstEnrollment) {
        events.push(EVENT_STUDENT_REG)
      }
      if (currentEnrollment?.examIntent === 'YES') {
        events.push(EVENT_EXAM_REG)
      }

      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'success',
            view: '',
            group: firstEnrollment ? 'register' : 'enroll',
          },
          myApDataEvents: [
            {
              events,
              studentId: encryptedStudentId,
              courseId: enrollmentDetails?.testCd,
              epc,
              courseOrgId: enrollmentDetails?.orgId,
            },
          ],
        },
      })
    }
  }, [complete, appUser, encryptedStudentId, epc, enrollmentDetails, enrollments])

  return null
}

export const useAnalyticsStudentPreRegChangeEvent = ({ examIntent }) => {
  const { appUser } = useStudentInfo()
  let view = ''
  switch (examIntent) {
    case 'YES':
      view = 'from-yes'
      break

    case 'NO':
      view = 'from-no'
      break

    case 'UNDECIDED':
      view = 'from-und'
      break

    default:
      break
  }

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'change-intent',
            view,
            group: EVENT_EXAM_REG,
          },
        },
      })
    }
  }, [appUser])

  return null
}

export const useAnalyticsStudentRegChangeEvent = ({ enrollment }) => {
  const { appUser, encryptedStudentId, epc } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(enrollment) && !isEmpty(appUser)) {
      const toYes = enrollment.examIntent === 'YES'

      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'intent-changed',
            view: toYes ? 'to-yes' : 'to-no',
            group: EVENT_EXAM_REG,
          },
          ...(toYes
            ? {
                myApDataEvents: [
                  {
                    events: [EVENT_EXAM_REG],
                    studentId: encryptedStudentId,
                    courseId: enrollment?.testCd,
                    epc,
                    courseOrgId: enrollment?.orgId,
                  },
                ],
              }
            : {}),
        },
      })
    }
  }, [enrollment, appUser, encryptedStudentId, epc])

  return null
}

export const useAnalyticsStudentScoreSendSelectRecipientEvent = ({ position, userPromptType }) => {
  const { appUser } = useStudentInfo()

  useEffect(() => {
    if (
      !isEmpty(appUser) &&
      position === ON_PAGE_TOP &&
      userPromptType === USER_PROMPT_MODAL_ACTION
    ) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'select-recipient',
            view: '',
            group: 'free-score-send',
          },
        },
      })
    }
  }, [appUser, position, userPromptType])

  return null
}

export const useAnalyticsStudentScoreSendSuccessEvent = ({
  userPromptType,
  loading,
  scoreSendSaved,
}) => {
  const { appUser } = useStudentInfo()

  useEffect(() => {
    if (
      !isEmpty(appUser) &&
      userPromptType === USER_PROMPT_MODAL_ACTION &&
      !loading &&
      scoreSendSaved
    ) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'success',
            view: '',
            group: 'free-score-send',
          },
        },
      })
    }
  }, [appUser, userPromptType, loading, scoreSendSaved])

  return null
}

export const useAnalyticsStudentScoreSendOptOutEvent = ({ step }) => {
  const { appUser } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser) && step !== 0) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: 'declined',
            view: step === 5 ? 'success' : `step-${step}`,
            group: 'free-score-send',
          },
        },
      })
    }
  }, [appUser, step])

  return null
}

export const useAnalyticsStudentProfileAndRegTabEvent = ({ tab }) => {
  const { appUser } = useStudentInfo()

  useEffect(() => {
    if (!isEmpty(appUser)) {
      fireAnalyticsEvent({
        testing: SET_TESTING,
        disabled: DISABLE_EVENTS,
        eventData: {
          appUser,
          appPage: {
            domain: 'student',
            page: tab === PROFILE ? 'ap-profile-tab' : 'ap-registration-tab',
            view: '',
            group: 'my-ap-profile',
          },
        },
      })
    }
  }, [appUser, tab])

  return null
}
