import { teacherRegistrationQuery } from '../graphql/teacher'

export const updateQueryTeacher = (prev, { subscriptionData }, KEY) => {
  if (!subscriptionData.data) return prev
  const courses = prev[KEY]
  const { data: updatedSection } = subscriptionData.data[KEY]
  const update = courses.map(c => ({
    ...c,
    sections: c.sections.map(s => {
      const { questionBank, sectionLink, units } =
        s.sectionId === updatedSection.sectionId ? updatedSection : s
      return {
        ...s,
        questionBank,
        sectionLink,
        units,
      }
    }),
  }))

  return { [KEY]: update }
}

export const updateTeacherRegistration = ({ client, variables, data }) => {
  try {
    client.writeQuery({
      query: teacherRegistrationQuery,
      variables,
      data: {
        getTeacherRegistration: {
          ...data,
          __typename: 'teacherRegistration',
        },
      },
    })
    return data
  } catch (err) {
    console.info(err)
    return {}
  }
}
