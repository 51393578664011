import { Icon } from '@cb/apricot-react'
import CourseFilter from './CourseFilter'
import CourseAnchor from './CourseAnchor'

import styles from './timeline.module.scss'

export default props => {
  const {
    isHorizontal,
    setView,
    view,
    filterProps,
    title,
    buttons,
    anchorId,
    anchorTitle,
    alwaysShowTitle,
  } = props

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          {title ? (
            <h2
              className={`cb-h4 ${styles['timeline-header']} ${
                isHorizontal || alwaysShowTitle ? '' : 'sr-only'
              }`}
            >
              {title}
            </h2>
          ) : null}
          {!isHorizontal && filterProps ? <CourseFilter {...filterProps} /> : null}
        </div>
        <div className="col-sm-6 col-xs-12 cb-align-right">
          {buttons
            ? buttons.map(b => (
                <button
                  type="button"
                  disabled={view === b.value}
                  aria-current={view === b.value}
                  key={b.value}
                  className={styles['toggle-button']}
                  id={b.id}
                  onClick={() => setView(b.value)}
                >
                  {/* We don't currently have icons for these buttons, can be re-enabled when icons are added */}
                  {/*b.icon ? <Icon name={b.icon} /> : null*/}
                  {b.label}
                </button>
              ))
            : null}
        </div>
      </div>
      {anchorId ? (
        <CourseAnchor anchorId={anchorId} anchorTitle={anchorTitle} showAnchor={!isHorizontal} />
      ) : null}
    </>
  )
}
