import Profile from './Profile'
import Register from './Register'
import * as c from './constants'

export default React.memo(({ isDismissable }) => {
  const [step, updateStep] = useState(c.UPDATE_PROFILE_INFO)
  let Component

  // add check to make sure step value is only updated if the new value exists and is different
  // from the previous valuethis may not be needed, but we need to try and prevent unnecessary
  // re-renderings
  const changeStep = newStep => {
    if (newStep && newStep !== step) {
      updateStep(newStep)
    }
  }

  switch (step) {
    case c.UPDATE_PROFILE_INFO:
      Component = Profile
      break
    case c.UPDATE_REGISTRATION:
      Component = Register
      break
    default:
      return null
  }

  return <Component changeStep={changeStep} isDismissable={isDismissable} />
})
