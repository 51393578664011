export const ENTER = 1
export const CONFIRM = 2
export const INFORMATION = 3
export const REGISTER = 4
export const SURVEY = 5
export const EXIT = 6
export const SAVE = 7

export const ALREADY_ENROLLED_ERROR =
  'Error: You have already joined this course or exam. Please contact your teacher or AP coordinator if you think you are seeing this message in error. '

export const LIVED_FIELD = 'livedInLanguageCountry'
export const SPOKEN_FIELD = 'languageRegularlySpoken'
