import { useMutation } from '@apollo/client'
import { teacherRegistrationMutation } from '../../../appsync/graphql/teacher'
import { userSettingsQuery } from '../../../appsync/graphql/settings'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useTeacherRegistrationMutation = ({ educationPeriod, input, onCompleted }) => {
  const [mutate, { data: rawData = {}, called, loading, error: rawError = null }] = useMutation(
    teacherRegistrationMutation,
    {
      variables: { code: educationPeriod, input },
      onCompleted,
    }
  )

  const data = rawData?.saveTeacherRegistration ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, called, loading, error }
}

export default useTeacherRegistrationMutation
