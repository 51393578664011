import { useMutation } from '@apollo/client'
import { studentInformationMutation } from '../../../appsync/graphql/student'
import { updateStudentInformation } from '../../../appsync/actions/student'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useStudentUpdateInformationMutation = ({ educationPeriod, refresh, onCompleted }) => {
  const [mutate, { data: rawData = {}, called, loading, error: rawError = null }] = useMutation(
    studentInformationMutation,
    {
      onCompleted,
      update(client, { data: { saveStudentInformation } }) {
        updateStudentInformation({
          client,
          educationPeriod,
          data: { ...saveStudentInformation },
          refresh,
        })
      },
    }
  )

  const data = rawData?.saveStudentInformation ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, called, loading, error }
}

export default useStudentUpdateInformationMutation
