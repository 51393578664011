import CourseCard from '../../_common/coursecard/CourseCard'
import ButtonLinks from './ButtonLinks'
import Status from '../enrollment/Status'
import EventLinks from './EventLinks'
import { cardsAsGrid } from '../../_common/coursecard/utils'

export default ({ userRole, courses, preview }) => {
  const columns = 3
  return cardsAsGrid(courses, columns).map((row, i) => (
    <div className="row" key={i}>
      {row.map(course => {
        const { testCd, isPreAP, sectionName, teachers, enrollmentId } = course
        const subtitle = teachers && teachers.length ? `${sectionName} with ${teachers[0]}` : 'Exam Only'
        return (
          <CourseCard key={testCd} course={course} columns={columns} subtitle={subtitle} preview={preview}>
            <ButtonLinks course={course} preview={preview} />
            <Status {...course} preview={preview} id={enrollmentId} />
            <EventLinks course={course} preview={preview} />
          </CourseCard>
        )
      })}
    </div>
    )
  )
}