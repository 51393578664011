import partition from 'lodash/partition'
import { useLazyQuery } from '@apollo/client'
import {
  COURSE_LABELS,
  isCoordinator,
  isAdmin,
  isAnyDistrict,
  CMS_COORDINATOR,
  CMS_ADMIN,
  CMS_DISTRICT,
} from '@myap/metadata'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import { getSystemDatesQuery } from '../../../appsync/graphql/settings'
import { getQueryDataWithoutKey } from '../../../appsync/utils'
import { filterByCourseCategory, filterByTestCd } from '../../_common/content/utils'
import { EVENT_EXAM_DATE } from '../../_common/content/timeline/constants'
import { getCMSTimelineEvents } from '../../../rest/cms'

const useTimeline = ({ preloadedDates, courses }) => {
  const [timelineEvents, setTimelineEvents] = useState([])
  const [cmsRole, setCMSRole] = useState(null)
  const [getDates, { data: systemDatesData }] = useLazyQuery(getSystemDatesQuery)

  // Get user details
  const {
    userDetails: { selectedOrgId, initialOrgId, roles },
    educationPeriod: { code: educationPeriod },
    systemDates,
  } = useUserSettingsQuery()

  const [dates, setDates] = useState(preloadedDates || systemDates)

  useEffect(() => {
    if (selectedOrgId !== initialOrgId) {
      getDates({ variables: { educationPeriod, orgId: selectedOrgId } })
    }
  }, [selectedOrgId])

  useEffect(() => {
    if (systemDatesData) {
      setDates(getQueryDataWithoutKey(systemDatesData))
    }
  }, [systemDatesData])

  useEffect(() => {
    if (roles.length && selectedOrgId) {
      const role = roles.find(role => role.orgId === selectedOrgId)
      setCMSRole(
        isCoordinator(role)
          ? CMS_COORDINATOR
          : isAdmin(role)
          ? CMS_ADMIN
          : isAnyDistrict(role)
          ? CMS_DISTRICT
          : ''
      )
    }
  }, [roles.length, selectedOrgId])

  useEffect(() => {
    const fetch = async () => {
      const cmsEvents = await getCMSTimelineEvents(cmsRole, educationPeriod)
      const filtered = filterByTestCd(courses, filterByCourseCategory(courses, cmsEvents))
      const [systemDateEvents, otherEvents] = partition(filtered, ev => ev.systemDate || !ev.date)
      const processedOtherEvents = otherEvents.map(ev => {
        const dateArr = ev.date.split(' to ')
        return dateArr.length > 1 ? { ...ev, date: dateArr[0], endDate: dateArr[1] } : ev
      })

      const processedSystemEvents = systemDateEvents.reduce((acc, ev) => {
        const { deadlines = {}, examDecisions = {}, examDates = {} } = dates
        const isExamDate = ev.systemDate === EVENT_EXAM_DATE
        const examDecisionDeadline = examDecisions[ev.systemDate]
        const deadlineKey = Object.keys(deadlines).find(key => key === ev.systemDate)

        if (deadlineKey) {
          return [...acc, { ...ev, date: deadlines[deadlineKey] }]
        }

        if (examDecisionDeadline) {
          // exam decisions can be single date or array of dates (student)
          const decisions = Array.isArray(examDecisionDeadline)
            ? examDecisionDeadline.map(d => ({ ...ev, date: d }))
            : { ...ev, date: examDecisionDeadline }
          return [...acc, ...decisions]
        }

        if (isExamDate)
          return [
            ...acc,
            ...Object.keys(examDates).reduce((acc, testCd) => {
              const testCdInt = parseInt(testCd, 10)
              const courseName = COURSE_LABELS[testCdInt]
              return [
                ...acc,
                ...examDates[testCd].map(course => ({
                  ...ev,
                  title: `${courseName}: ${ev.title} ${
                    course.displayName ? `(${course.displayName})` : ''
                  }`,
                  date: course.examDate,
                  testCd: testCdInt,
                })),
              ]
            }, []),
          ]
        return acc
      }, [])
      setTimelineEvents([...processedSystemEvents, ...processedOtherEvents])
    }

    if (educationPeriod && cmsRole) {
      fetch()
    }
  }, [dates, cmsRole, educationPeriod, courses])

  return { events: timelineEvents, dates }
}

export default useTimeline
