import {
  isDateSameOrAfter,
  formatDate,
  DATETIME_FORMATS,
  Breakpoint,
  closestToDateIndex,
} from '@myap/ui-library'
import { TIMELINE_ID, HORIZONTAL_BUTTON_ID, VERTICAL_BUTTON_ID, VISIBLE_COUNT } from './constants'

import styles from './timeline.module.scss'

export default Breakpoint(({ events, breakpoint }) => {
  const verticalOnlyView = events.length < VISIBLE_COUNT
  const showToggleButtons = !breakpoint.mobile && !verticalOnlyView
  const currentDate = new Date()
  const futureEvents = events.filter(e => isDateSameOrAfter(e.date, currentDate))
  const startIndex = futureEvents.length
    ? closestToDateIndex(
        currentDate,
        futureEvents.map(e => e.date)
      )
    : -1

  if (startIndex >= 0) {
    const { title = '', date = '' } = futureEvents[startIndex]
    return (
      <div className={`cb-gray5-bg ${styles.navigation}`}>
        <div className="container">
          <div className={styles['timeline-teaser']}>
            Upcoming: {title}{' '}
            <button
              type="button"
              className="cb-btn-naked"
              onClick={() => {
                const timelineContainer = document.querySelector(`#${TIMELINE_ID}`)
                timelineContainer.scrollIntoView({ block: 'start', behavior: 'smooth' })
                if (showToggleButtons) {
                  const timelineHorizontalButton = document.querySelector(
                    `#${HORIZONTAL_BUTTON_ID}`
                  )
                  timelineHorizontalButton.click()
                }
              }}
            >
              {formatDate(date, DATETIME_FORMATS.mediumMonthDayYear)}
            </button>{' '}
            <button
              type="button"
              className="cb-btn-naked"
              onClick={() => {
                const timelineContainer = document.querySelector(`#${TIMELINE_ID}`)
                timelineContainer.scrollIntoView({ block: 'start', behavior: 'smooth' })
                if (showToggleButtons) {
                  const timelineVerticalButton = document.querySelector(`#${VERTICAL_BUTTON_ID}`)
                  timelineVerticalButton.click()
                }
              }}
            >
              See all dates &#8595;
            </button>
          </div>
        </div>
      </div>
    )
  }

  return null
})
