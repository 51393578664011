import { Modal, PrimaryButton } from '@cb/apricot-react'
import { Error, Spinner } from '@myap/ui-library'
import useUserSettingsQuery from '../../../../hooks/useUserSettingsQuery'
import useStudentJoinCourseDetailsQuery from '../../hooks/useStudentJoinCourseDetailsQuery'
import useStudentSectionDetailsQuery from '../../hooks/useStudentSectionDetailsQuery'
import useStudentEnrollmentsQuery from '../../hooks/useStudentEnrollmentsQuery'
import useStudentAddEnrollmentMutation from '../../hooks/useStudentAddEnrollmentMutation'
import { useAnalyticsStudentRegSuccessEvent } from '../../../../hooks/analyticsHooks'

import styles from '../joincourse.module.scss'

function Message({ courseName, error, loading, success }) {
  if (error) {
    return <Error title="Error enrolling" message={error} />
  }

  if (loading) {
    return (
      <div className={styles['save-loader']}>
        <Spinner spinnerId="joinSpinner" size="64" ariaLabel="Saving..." />
      </div>
    )
  }

  if (success) {
    return (
      <div className={styles.save}>
        You&#39;ve enrolled in
        <br />
        {courseName}!
      </div>
    )
  }

  return null
}

function FooterActions({ busy }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <PrimaryButton disabled={busy} loading={busy} data-cb-modal-close={true}>
        Close
      </PrimaryButton>
    </div>
  )
}

function SaveEnrollment() {
  // Get user details
  const {
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  // Add enrollment
  const {
    mutate,
    called,
    loading: saving,
    error,
  } = useStudentAddEnrollmentMutation({ educationPeriod })

  // Get join course details
  const { data: input } = useStudentJoinCourseDetailsQuery({})

  // Get enrollments
  const { data: enrollmentsData } = useStudentEnrollmentsQuery({
    educationPeriod,
    otherSkipConditions: !called || (called && saving),
  })

  // Get section details
  const { data: details } = useStudentSectionDetailsQuery({ enrollmentCode: input?.enrollmentCode })

  useAnalyticsStudentRegSuccessEvent({
    complete: called && !saving && enrollmentsData?.courseEnrollments?.length,
    enrollmentDetails: details,
    enrollments: enrollmentsData?.courseEnrollments,
  })

  useEffect(() => {
    if (!called && !saving && educationPeriod && input) {
      const { __typename, ...restInput } = input
      mutate({
        variables: {
          code: educationPeriod,
          input: { ...restInput },
        },
      })
    }
  }, [called, saving, educationPeriod, input])

  return (
    <Modal
      elem="SaveEnrollmentModal"
      open={true}
      openAnimation={false}
      closeAnimation={true}
      footer={<FooterActions busy={saving} />}
      shadowRoot
      modalNode
    >
      <Message
        courseName={details?.courseName}
        error={error}
        loading={saving}
        success={called && !saving}
      />
    </Modal>
  )
}

export default SaveEnrollment
