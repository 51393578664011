import { useMutation } from '@apollo/client'
import { ButtonRow } from '@cb/apricot-react'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import { saveStudentScoreSend } from '../../../appsync/actions/student'
import { saveStudentScoreSendMutation } from '../../../appsync/graphql/student'
import GoToScoreSendsButton from './GoToScoreSendsButton'

function ScoreSendInformationalContent({ setShouldCloseModal }) {
  // Get user details
  const {
    userDetails: { personId: studentId },
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  const [updateStudentScoreSend] = useMutation(saveStudentScoreSendMutation, {
    update(cache, response) {
      saveStudentScoreSend({ cache, response, educationPeriod })
    },
    onCompleted: () => setShouldCloseModal(true),
  })
  return (
    <>
      <div className="cb-modal-content">
        <p>
          Each year you take AP Exams, you can have your AP score report sent for free to one
          recipient (a college, university, or scholarship organization). Your official score report
          includes the scores from all AP Exams taken this year and any AP Exams you&#39;ve
          previously taken, along with certain demographic information about you and other
          information you provide during testing. Use your score report to introduce yourself!
          Schools use your official score report to determine credit or placement, if applicable.
          Colleges, universities, and scholarship programs that you send your score to may also use
          your information and official score report to contact you with information about
          admissions, educational, financial aid, and scholarship opportunities. <strong>Go</strong>{' '}
          to the Free Score Sends page to learn more about sending AP score reports and select your
          recipient.
        </p>
      </div>
      <div className="cb-modal-footer">
        <ButtonRow>
          <button
            type="button"
            className="cb-btn cb-btn-naked"
            onClick={() => {
              updateStudentScoreSend({
                variables: {
                  educationPeriod,
                  studentId,
                  optOut: true,
                },
              })
            }}
          >
            Continue to My AP
          </button>
          <GoToScoreSendsButton
            updateFunc={() => {
              updateStudentScoreSend({
                variables: {
                  educationPeriod,
                  studentId,
                  optOut: true,
                },
              })
            }}
          />
        </ButtonRow>
      </div>
    </>
  )
}

export default ScoreSendInformationalContent
