import { RESEARCH, COMPUTER_SCIENCE_PRINCIPLES, SEMINAR } from '@myap/metadata'
import ToggleInactiveLink from '../../_common/actions/ToggleInactiveLink'
import ClassroomLink from '../../_common/actions/ClassroomLink'
import styles from './buttonlinks.module.scss'

export default ({ course, preview }) => {
  const { testCd, isPreAP, isStudioArt, isDigitalPortfolio, disableSecondaryAPClassroomLinks } = course
  const isResearch = testCd === RESEARCH
  const isSeminar = testCd === SEMINAR
  const isCSP = testCd === COMPUTER_SCIENCE_PRINCIPLES
  const links = [
    {
      title: 'Course Guide',
      link: `${testCd}/home`,
      className: `cb-hover ${styles['course-resources']}`,
      show: !isPreAP && !isStudioArt && testCd !== RESEARCH,
    },
    {
      title: 'Assignments',
      link: `${testCd}/assignments?status=assigned`,
      className: `cb-hover ${styles.assignments}`,
      show: !isPreAP && !isStudioArt && testCd !== RESEARCH,
    },
  ]

  const Link = ({ title }) => {
    const words = title.split(' ')
    const final = words.pop()
    return (
      <>
        {words.join(' ')}{' '}
        <span className={styles['no-wrap']}>
          {final} <span className="cb-icon cb-east" aria-hidden={true} />
        </span>
      </>
    )
  }

  return links.map((l, i) => {
    const { title, className = '', link, show } = l
    const component = <Link title={title} />
    const isNotClassroomLink = /http/.test(link)
    const classes = `${styles['action-link']} ${className}`

    return show ? (
      <React.Fragment key={i}>
        {isNotClassroomLink ? (
          <ToggleInactiveLink inactive={preview} href={link} className={classes}>
            {component}
          </ToggleInactiveLink>
        ) : !disableSecondaryAPClassroomLinks ? (
          <ClassroomLink link={preview ? '#' : link} title={component} className={classes} />
        ) : null}
      </React.Fragment>
    ) : null
  })
}
