import { isEmpty } from '@myap/ui-library'
import { GRADE_OPTIONS } from '@myap/metadata'
import Banner from '../../_common/banner/Banner'
import { OpenModal } from '../../_common/apricot/Modal'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentInfoAndRegistrationQuery from '../hooks/useStudentInfoAndRegistrationQuery'
import UpdatePersonalInfoModal from '../profileandreg/UpdatePersonalInfoModal'
import ToggleInactiveButtonLink from '../../_common/actions/ToggleInactiveButtonLink'
import styles from '../../_common/banner/banner.module.scss'

function StudentBanner({ educationPeriods, studentInfoAndRegistrationData = {} }) {
  // We're going to show the My AP Profile link if student has any enrollments in the
  // current or previous education period
  const isRegistered = educationPeriods.some(edpd => edpd.isRegistered)
  const hasData = Object.keys(studentInfoAndRegistrationData).length
  const { attendingOrg, grade, preferredFirstName, firstName } = studentInfoAndRegistrationData
  const orgName = attendingOrg?.orgName ?? null
  const { label } = GRADE_OPTIONS.find(g => g.value == grade) || {}

  return (
    <Banner
      isStudent={true}
      name={!isEmpty(preferredFirstName) ? preferredFirstName : firstName}
      subHeader={
        hasData ? (
          <div className={styles['sub-header']}>{`${orgName || ''}${
            label ? `, ${label}` : ''
          }`}</div>
        ) : null
      }
      navigation={
        isRegistered ? (
          <ToggleInactiveButtonLink
            onClick={() => OpenModal(UpdatePersonalInfoModal, {})}
            inactive={false}
            visuallyDisabled={false}
            light={true}
          >
            My AP Profile
          </ToggleInactiveButtonLink>
        ) : null
      }
    />
  )
}

function BannerWrapper() {
  const [educationPeriod, setEducationPeriod] = useState(null)
  const [currentYearEdpd, setCurrentYearEdpd] = useState({})
  const [previousYearEdpd, setPreviousYearEdpd] = useState({})

  // Get user details
  const {
    userDetails: { personId },
    educationPeriods,
  } = useUserSettingsQuery()

  // Get student info and registration
  const { data: studentInfoAndRegistrationData } = useStudentInfoAndRegistrationQuery({
    educationPeriod,
  })

  useEffect(() => {
    setCurrentYearEdpd(educationPeriods.find(edpd => edpd.chronology === 'current') ?? {})
    setPreviousYearEdpd(educationPeriods.find(edpd => edpd.chronology === 'previous') ?? {})
  }, [JSON.stringify(educationPeriods)])

  useEffect(() => {
    // Get the epc for edpd with enrollments
    const code =
      currentYearEdpd.isRegistered && currentYearEdpd.hasEnrollments
        ? currentYearEdpd.code
        : previousYearEdpd.isRegistered && previousYearEdpd.hasEnrollments
        ? previousYearEdpd.code
        : null
    setEducationPeriod(code)
  }, [currentYearEdpd, previousYearEdpd])

  if (isEmpty(personId) || educationPeriod === null) {
    return <Banner isStudent={true} />
  }

  return (
    <StudentBanner
      educationPeriods={educationPeriods}
      studentInfoAndRegistrationData={studentInfoAndRegistrationData}
    />
  )
}

export default BannerWrapper
