import SelectIntent from './SelectIntent'
import RegisterSuccess from './RegisterSuccess'
import * as c from './constants'

export default props => {
  const [step, changeStep] = useState(0)
  let Component

  switch (step) {
    case c.REGISTER:
      Component = SelectIntent
      break
    case c.SUCCESS:
      Component = RegisterSuccess
      break
    default:
      return null
  }

  return <Component {...props} changeStep={changeStep} />
}
