import { useLazyQuery } from '@apollo/client'
import { districtInvoiceDownloadUrlQuery } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

const useDistrictInvoiceDownloadUrlQuery = ({
  orgId,
  educationPeriod,
  otherSkipConditions = false,
}) => {
  const [getDownloadUrl, { loading, error: rawError = null, data: rawData = {}, refetch, client }] =
    useLazyQuery(districtInvoiceDownloadUrlQuery, {
      variables: { orgId, educationPeriod, getUrl: true },
      skip: !orgId || !educationPeriod || otherSkipConditions,
      nextFetchPolicy: 'network-only',
    })
  const data = rawData?.getDistrictInvoiceDownloadUrl?.invoiceDownloadUrl ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { getDownloadUrl, data, loading, error, refetch }
}

export default useDistrictInvoiceDownloadUrlQuery
