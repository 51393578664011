import styles from './actions.module.scss'

function ToggleInactiveLink({
  children,
  onClick = null,
  ariaId,
  className = '',
  visuallyDisabled = true,
  inactive,
  href,
  light = false,
}) {
  return (
    <a
      href={inactive ? '#' : href}
      disabled={inactive && visuallyDisabled}
      aria-describedby={ariaId}
      className={`cb-hover${light ? ' cb-link-white' : ' cb-palette-blue5'} ${
        inactive ? styles['fake-disabled-link'] : ''
      } ${className}`}
      tabIndex={inactive ? '-1' : '0'}
      onClick={inactive ? e => e.preventDefault() : onClick}
    >
      {children}
    </a>
  )
}

export default ToggleInactiveLink
