import { Icon } from '@cb/apricot-react'
import { profile, formatDate, hasSameMonth } from '@myap/ui-library'
import styles from '../../_common/content/timeline/timeline.module.scss'

const Event = ({ events }) => {
  const { classroomUrl } = profile()
  const [courses, content] = events.reduce(
    (arr, e) => {
      const index = e.courses ? 0 : 1

      if (index === 1) arr[index].push(e)
      else {
        const isOverDue = false // isDatePast(date), not in scope
        const formatted = e.courses.map(c => ({
          courseName: c.courseName,
          testCd: c.testCd,
          dueCount: isOverDue ? 0 : c.sections.length,
          overdueCount: isOverDue ? c.sections.length : 0,
          link: c.assignmentsLink,
        }))
        const unique = formatted.reduce((acc, f) => {
          const i = arr[index].findIndex(a => a.testCd === f.testCd)
          const existing = arr[index][i]

          if (existing)
            arr[index][i] = {
              ...existing,
              dueCount: f.dueCount + existing.dueCount,
              overdueCount: f.overdueCount + existing.overdueCount,
            }

          return existing ? acc : [...acc, f]
        }, [])

        Array.prototype.push.apply(arr[index], unique)
      }

      return arr
    },
    [[], []]
  )

  return (
    <>
      {courses.map(c => (
        <React.Fragment key={c.testCd}>
          <h5>{c.courseName}</h5>
          <ul>
            {c.dueCount ? (
              <li>
                <a href={`${classroomUrl}/${c.link}`}>
                  {c.dueCount} assignment{c.dueCount > 1 ? 's' : ''}
                </a>
              </li>
            ) : null}
            {c.overdueCount ? (
              <li className={styles.overdue}>
                {c.overdueCount} assignment{c.overdueCount > 1 ? 's' : ''}
              </li>
            ) : null}
          </ul>
        </React.Fragment>
      ))}
      {content.map((c, i) => (
        <React.Fragment key={`${c.date}_${i}`}>
          <h5>
            <Icon name="cal-full" /> {c.title}
          </h5>
          <div>{formatDate(c.endDate || c.date, 'MMM d')}</div>
          {c.link ? (
            <div>
              <a href={c.link.url}>
                <span>{c.link.title}</span>
              </a>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </>
  )
}

const NoEvents = () => (
  <p className={styles['no-events']}>
    <em>No events this week</em>
  </p>
)

export default ({ items }) => {
  return items.map((week, i) => {
    const { events, startDate, endDate } = week
    const start = formatDate(startDate, 'MMM d')
    const end = formatDate(endDate, hasSameMonth(startDate, endDate) ? 'd' : 'MMM d')

    return (
      <div className={`row ${styles['event-assignment']}`} key={i}>
        <div className="col-sm-4">
          <h4>
            {start}-{end}
          </h4>
        </div>
        <div className="col-sm-8">{events ? <Event events={events} /> : <NoEvents />}</div>
      </div>
    )
  })
}
