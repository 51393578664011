import { CMS_ADMIN } from '@myap/metadata'
import Dashboard from '../professional/Dashboard'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useAdminCoursesQuery from '../professional/hooks/useAdminCoursesQuery'
import useAdminStatusQuery from '../professional/hooks/useAdminStatusQuery'
import useTimeline from '../professional/hooks/useTimeline'
import { updateQueryProfessionalFineTune } from '../../appsync/actions/professional'

function AdminApp() {
  const [needToCompletePartForm, setNeedToCompletePartForm] = useState(null)
  const [showOrderData, setShowOrderData] = useState(null)

  // Get user details
  const {
    userDetails: { selectedOrgId: orgId, roles },
    educationPeriod: { code: educationPeriod, isTransitionPeriod },
  } = useUserSettingsQuery()

  useEffect(() => {
    if (roles.length && orgId) {
      const role = roles.find(role => role.orgId === orgId)
      setNeedToCompletePartForm(role?.needToCompletePartForm)
      setShowOrderData(role?.showOrderData)
    }
  }, [roles.length, orgId])

  // Get admin status
  const status = useAdminStatusQuery({
    orgId,
    educationPeriod,
  })

  // Get admin courses
  const courses = useAdminCoursesQuery({
    orgId,
    educationPeriod,
  })

  const { events } = useTimeline({ courses: courses?.data })

  return (
    <Dashboard
      events={events}
      status={status}
      courses={courses}
      cmsRole={CMS_ADMIN}
      needToCompletePartForm={needToCompletePartForm}
      isTransitionPeriod={isTransitionPeriod}
      showOrderData={showOrderData}
    />
  )
}

export default AdminApp
