import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'

function Flag({ feature, renderEnabled, renderDisabled, children }) {
  const { inactiveFeatures } = useUserSettingsQuery()
  const featureEnabled = inactiveFeatures.findIndex(f => f === feature) === -1

  if (featureEnabled && children) {
    return children
  }

  if (featureEnabled) {
    if (renderEnabled) {
      return renderEnabled
    }
  } else if (renderDisabled) {
    return renderDisabled
  }

  return null
}

export default Flag
