import { gql } from '@apollo/client'

export const saveDeletedNotificationMutation = gql`
  mutation saveDeletedNotification($id: String!, $educationPeriod: Int!) {
    saveDeletedNotification(id: $id, educationPeriod: $educationPeriod)
  }
`

export const userPreferencesQuery = gql`
  query getUserPreferences($educationPeriod: Int!) {
    getUserPreferences(educationPeriod: $educationPeriod) {
      deletedNotifications
    }
  }
`
