import { Modal } from '@cb/apricot-react'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentEnrollmentsQuery from '../hooks/useStudentEnrollmentsQuery'
import { useAnalyticsStudentRegChangeEvent } from '../../../hooks/analyticsHooks'
import { SELECTED_YES } from './constants'

import styles from './register.module.scss'

function SuccessMessage({ enrollment = {} }) {
  const { examIntent, courseName } = enrollment

  return (
    <div className={styles['register-success']}>
      {examIntent === SELECTED_YES ? (
        <div className="cb-h1">
          You are now registered for
          <br />
          the {courseName} exam!
        </div>
      ) : (
        <div className="cb-h2">
          You have confirmed that you are not registered for the {courseName} exam.
        </div>
      )}
    </div>
  )
}

function RegisterSuccess({ enrollmentId }) {
  // Get user details
  const {
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  // Get student enrollments
  const {
    data: enrollmentsData,
    loading: loadingEnrollments,
    error: errorEnrollments,
  } = useStudentEnrollmentsQuery({ educationPeriod })

  const enrollment = enrollmentsData?.courseEnrollments.find(d => d.enrollmentId === enrollmentId)

  useAnalyticsStudentRegChangeEvent({ enrollment })

  return (
    <Modal
      open={true}
      title={`Register for ${enrollment.examStartTime ? 'Exam' : 'Assessment'}`}
      closeButtonLabel="OK"
      shadowRoot
      modalNode
    >
      <SuccessMessage enrollment={enrollment} />
    </Modal>
  )
}

export default RegisterSuccess
