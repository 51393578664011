import { districtCourseQuery, districtRefreshMutation } from '../graphql/professional'

export const setDistrictCourse = (client, data) => {
  const { educationPeriod: code, testCd, orgId, parentOrgId } = data || {}

  try {
    client.writeQuery({
      query: districtCourseQuery,
      variables: { orgId, parentOrgId, testCd, educationPeriod: code },
      data: { getDistrictCourse: data },
    })
  } catch (err) {
    console.info(err)
  }
}

export const updateQueryDistrictStatus = (prev, { subscriptionData }) =>
  subscriptionData.data || prev

export const updateQueryDistrictCourse = (prev, { subscriptionData }, KEY, client) => {
  if (!subscriptionData.data) return prev

  const { [KEY]: updatedCourse } = subscriptionData.data
  const courses = prev[KEY]
  const index = courses.findIndex(c => c.data.testCd === updatedCourse.testCd)
  const updatedData = [...courses]

  if (index === -1) updatedData.push(updatedCourse)
  else updatedData[index] = updatedCourse

  setDistrictCourse(client, updatedCourse)
  return { [KEY]: updatedData }
}

export const updateQueryProfessionalFineTune = (prev, { subscriptionData }, KEY) => {
  if (!subscriptionData.data) return prev
  const courses = prev[KEY]
  const { data, testCd } = subscriptionData.data[KEY]
  const updatedCourses = courses.map(c => {
    const hasUpdates = c.testCd === testCd
    return hasUpdates
      ? {
          ...c,
          teachers: c.teachers.map(t => {
            const updates = data.find(d => d.teacherId === t.teacherId) || {}
            return { ...t, ...updates }
          }),
        }
      : c
  })

  return { [KEY]: updatedCourses }
}

export const refreshDistrictData = async (client, variables) => {
  try {
    const data = await client.mutate({
      mutation: districtRefreshMutation,
      variables,
    })
    return data
  } catch (err) {
    console.info(err)
  }
}
