import { useMutation } from '@apollo/client'
import { districtRefreshMutation } from '../../../appsync/graphql/professional'
import { formatAppsyncErrors } from '../../../appsync/utils'

// Sends an SQS message to update data for district's child orgs
// Returns true if no errors
const useRefreshDistrictDataMutation = ({ orgId, educationPeriod, onCompleted }) => {
  const [mutate, { loading, error: rawError = null, data: rawData = {} }] = useMutation(
    districtRefreshMutation,
    {
      variables: { orgId, educationPeriod },
      onCompleted,
    }
  )
  const data = rawData?.refreshDistrictData?.data ?? {}
  const error = rawError ? formatAppsyncErrors(rawError) : null

  return { mutate, data, loading, error }
}

export default useRefreshDistrictDataMutation
