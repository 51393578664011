import { useApolloClient } from '@apollo/client'
import { ErrorNotification } from '@cb/apricot-react'
import orderBy from 'lodash/orderBy'
import { CMS_STUDENT } from '@myap/metadata/dist/user'
import { Spinner } from '@myap/ui-library'
import Banner from '../student/banner/Banner'
import CourseCards from '../student/coursecards/CourseCards'
import Notifications from '../_common/content/notifications/Notifications'
import StudentRegistrationNeeded from '../student/userinforequests/StudentRegistrationNeeded'
import Resources from '../student/resources/Resources'
import Timeline from '../student/timeline/Timeline'
import DashboardAnalytics from '../student/dashboard/DashboardAnalytics'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useStudentEnrollmentsQuery from '../student/hooks/useStudentEnrollmentsQuery'
import { setStudentEnrollments } from '../../appsync/actions/student'
import ScoreSend from '../student/scoresend/ScoreSend'
import { ON_PAGE_TOP, ON_PAGE_BOTTOM } from '../student/scoresend/constants'

function StudentApp() {
  const client = useApolloClient()
  const courseCardContainerId = 'studentCourseCards'

  // Get user details
  const {
    loading: loadingUserDetails,
    userDetails: { personId: studentId },
    educationPeriod: { code: educationPeriod, isTransitionPeriod, hasEnrollments },
  } = useUserSettingsQuery()

  // Get student enrollments
  const {
    data: enrollmentsData,
    loading: loadingEnrollments,
    error: errorEnrollments,
  } = useStudentEnrollmentsQuery({ educationPeriod, otherSkipConditions: !hasEnrollments })

  useEffect(() => {
    if (!loadingUserDetails && !studentId) {
      // Set some default data
      setStudentEnrollments({ client, educationPeriod })
    }
  }, [studentId])

  if (errorEnrollments) {
    return <ErrorNotification>{errorEnrollments}</ErrorNotification>
  }

  if (loadingEnrollments) {
    return <Spinner spinnerId="districtSpinner" size="64" />
  }

  return (
    <>
      <Banner />
      <DashboardAnalytics />
      <Notifications
        courses={enrollmentsData?.courseEnrollments}
        userRole={CMS_STUDENT}
        showAsDropdown={false}
      />
      <StudentRegistrationNeeded />
      <Timeline enrollments={enrollmentsData?.courseEnrollments} anchorId={courseCardContainerId} />
      <div className="container cb-margin-top-48" id={courseCardContainerId} tabIndex="-1">
        <ScoreSend position={ON_PAGE_TOP} scoreSendData={enrollmentsData?.scoreSendData} />
        {!isTransitionPeriod ? (
          <>
            <div className="row">
              <div className="col-xs-12">
                <h2 className="cb-h4 cb-margin-bottom-24">Courses &amp; Assignments</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <CourseCards
                  courses={orderBy(enrollmentsData?.courseEnrollments, ['courseName'], ['asc'])}
                />
              </div>
            </div>
          </>
        ) : null}
        <ScoreSend position={ON_PAGE_BOTTOM} scoreSendData={enrollmentsData?.scoreSendData} />
        <Resources
          enrollments={enrollmentsData?.courseEnrollments}
          hideJoinCourse={isTransitionPeriod}
        />
      </div>
    </>
  )
}

export default StudentApp
