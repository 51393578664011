import { useId } from 'react'
import { BareModal } from '@cb/apricot-react'
import ProfileForm from '../profileandreg/ProfileForm'
import { UPDATE_REGISTRATION } from './constants'
import { adjustModalHeight } from '../../../utilities/dom'
import Header from './Header'

const ID = 'studentProfileModal'

function StudentProfileModal({ changeStep }) {
  const useAnimation = true
  const adjustHeight = () => adjustModalHeight(`#${ID}`)
  const modalHeaderId = useId()
  return (
    <BareModal
      modalId={ID}
      open={true}
      openAnimation={useAnimation}
      closeAnimation={useAnimation}
      clickOverlayToClose={false}
      escClose={false}
      shadowRoot
      modalNode
    >
      <Header headerId={modalHeaderId} />
      <ProfileForm
        withHeader={false}
        isReviewMissingInfo={true}
        changeStep={changeStep}
        nextStep={UPDATE_REGISTRATION}
        adjustHeight={adjustHeight}
      />
    </BareModal>
  )
}

StudentProfileModal.displayName = 'Student Profile Modal'

export default StudentProfileModal
