import { Spinner } from '@myap/ui-library'
import { checkByTestCd, filterByCourseCategory } from '../utils'
import { getCMSResources } from '../../../../rest/cms'

export default ({ courses = [], userRole, Component, showLoading = false }) => {
  const [loading, setLoading] = useState(true)
  const [resources, setResources] = useState([])
  const [resourceObj, setResourceObj] = useState({})
  const hasResources = resources.length

  useEffect(() => {
    setResourceObj(
      Array.isArray(resources) &&
        resources.reduce((obj, resource) => {
          const { key, links = [], content = null, title = null, button = null } = resource
          const filteredLinks = filterByCourseCategory(courses, links)
          const userHasCourse = checkByTestCd(courses, resource)
          const resourceHasContent = filteredLinks.length || content

          return resourceHasContent && userHasCourse
            ? { ...obj, [key]: { content, title, links: filteredLinks, button } }
            : obj
        }, {})
    )
  }, [resources])

  useEffect(() => {
    const fetch = async () => {
      const data = await getCMSResources(userRole)
      setResources(data)
      setLoading(false)
    }

    fetch()
  }, [])

  if (loading && showLoading) return <Spinner spinnerId="resourceSpinner" />

  return hasResources ? <Component {...resourceObj} /> : null
}
