import { Modal } from '@cb/apricot-react'
import useStudentJoinCourseDetailsQuery from '../../hooks/useStudentJoinCourseDetailsQuery'
import useStudentSectionDetailsQuery from '../../hooks/useStudentSectionDetailsQuery'
import { useAnalyticsStudentEnrollStep1WrongCodeEvent } from '../../../../hooks/analyticsHooks'

function Message() {
  return (
    <>
      <p>
        If you were expecting a different course or exam, double check that you entered the join
        code correctly.
      </p>
      <p>If you aren&#39;t sure what to do next, ask your teacher how to enroll online.</p>
    </>
  )
}

function ExitMessageStep() {
  // Get join course details
  const { data: joinCourseData } = useStudentJoinCourseDetailsQuery({})
  // Get section details
  const {
    data: { teachers },
  } = useStudentSectionDetailsQuery({ enrollmentCode: joinCourseData?.enrollmentCode })

  useAnalyticsStudentEnrollStep1WrongCodeEvent()

  return (
    <Modal
      title={`No, I am not in this ${teachers?.length ? 'course' : 'exam'}`}
      openAnimation={false}
      open={true}
      shadowRoot
      modalNode
    >
      <Message />
    </Modal>
  )
}

export default ExitMessageStep
